import { ChakraProvider } from "@chakra-ui/react";
import React, { useEffect, useState, StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import { store, persiststore } from "./store";
import theme from "./chakra-theme";
import { PersistGate } from "redux-persist/integration/react";
import SplashScreen from "./components/Others/SplashScreen";
import { clarity } from "react-microsoft-clarity";

localStorage.setItem("chakra-ui-color-mode", "light");

const RootComponent = () => {
  const [isready, setisready] = useState(false);

  useEffect(() => {
    clarity.init("l6l1piyvmg");

    if (clarity.hasStarted()) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("utm_medium")) {
        clarity.setTag("referedBy", `${urlParams.get("utm_medium")}`);
      }
    }

    if (isready) {
      window.Beacon("init", "f5b97d10-4d6c-42dd-84cf-0c4a8b143490");
    }
  }, [isready]);

  useEffect(() => {
    setTimeout(() => {
      window.Beacon("init", "f5b97d10-4d6c-42dd-84cf-0c4a8b143490");
      setisready(true);
    }, 2000);
  }, []);

  return (
    <StrictMode>
      {!isready && <SplashScreen />}

      {isready && (
        <ChakraProvider theme={theme}>
          <Provider store={store}>
            <PersistGate persistor={persiststore}>
              <App />
            </PersistGate>
          </Provider>
        </ChakraProvider>
      )}
    </StrictMode>
  );
};

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(<RootComponent />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
