import { API_BASE_URL } from "./apiConstants";
import axios from "axios";

const getLoanApplicationDetails = async (loanId) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    try {
        const { data } = await axios.get(`${API_BASE_URL}/loans/requests/${loanId}`, config);
        return data.data;

    } catch (error) {
    }
}

const loanService = {
    getLoanApplicationDetails,
}

export default loanService;