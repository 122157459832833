import {
  Box,
  Flex,
  Text,
  Divider,
  HStack,
  VStack,
  Button,
  Stack,
} from "@chakra-ui/react";
import "@smile_identity/smart-camera-web";
import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../features/loanSlice";

const ConvenantForm = ({ setCurrentScreen }) => {
  const allLoanData = useSelector((state) => state.loanData);

  const [signature, setSignature] = useState(allLoanData?.signature || "");
  const [signatureName, setSignatureName] = useState(
    allLoanData?.signatureName ||
      allLoanData?.signature?.split("-")?.splice(-1)[0] ||
      ""
  );
  const [signatureErr, setSignatureErr] = useState("");

  const dispatch = useDispatch();

  const initialvalues = {
    date: allLoanData?.witness_date.split(" ")[0] || "",
  };

  const validationSchemaData = {
    date: Yup.date(),
  };

  const submitBtn = async (values, { setSubmitting }) => {
    if (!signature) {
      setSignatureErr("Signature is required");
      return;
    }
    setSignatureErr("");

    const witnessData = {
      witness_date: values.date,
      signature: signature,
      signatureName: signatureName,
      // completed: "true",
    };

    dispatch(updateField(witnessData));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setCurrentScreen(5);
  };

  const goBack = () => {
    setCurrentScreen(3);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Flex justify="center">
      <Box
        w={["100%", "80%", "60%"]}
        boxShadow="0px 4px 12px 0px #D7D7D740"
        border="0.5px solid #EFEAED"
        borderRadius="10px"
      >
        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          COVENANT
        </Text>
        <Divider
          color="#5B2E4F"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />
        <Box p={["10px", "20px"]}>
          <VStack spacing={6} align="baseline">
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              a) I declare that the information given herein is true to the best
              of my knowledge and belief. I further authorize HERVEST FOR WOMEN
              LIMITED to verify the information given herein and make reference
              from any person(s)/institution(s) named herein.
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              b) I hereby permit HERVEST FOR WOMEN LIMITED to conduct Credit
              Check on my name, and also share my financial details with
              appropriate regulatory authorities.
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              fontFamily="SatoshiBold"
              align="justify"
            >
              c) I agree to be bound by all the terms and conditions of this
              loan agreement upon acceptance of offer.
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              d) I authorise HERVEST FOR WOMEN LIMITED to date and narrate my
              cheque to cover the principal, interest, and other applicable
              charges for the payment of the loan granted to me and present same
              to my Bank in the event of default, without further recourse to
              me.
            </Text>

            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              e) I hereby authorise HERVEST FOR WOMEN LIMITED to set off my
              liability under this loan agreement against any credit balance in
              any account whatsoever in my name (whether sole or joint account
              with any person or persons), in Naira or Foreign currency and
              domiciled with any Financial Institution in Nigeria.
            </Text>

            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              f) I hereby grant HERVEST FOR WOMEN LIMITED the right to enter my
              residence and office/business location to recover the collateral
              pledged by me for this facility in the event of any default in the
              loan repayment in any of this loan instalment which falls due.
            </Text>
          </VStack>
          <Formik
            initialValues={initialvalues}
            validationSchema={Yup.object(validationSchemaData)}
            onSubmit={submitBtn}
          >
            {({ values }) => {
              return (
                <Form>
                  <Stack
                    direction={["column", "row"]}
                    w="100%"
                    spacing={6}
                    justify="space-between"
                    mt="30px"
                  >
                    <Box w={["100%", "50%"]}>
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="0.8px"
                        mb="5px"
                      >
                        <label htmlFor="signature" fontFamily="SatoshiBold">
                          Upload or Append Signature
                        </label>
                      </Text>
                      <Flex
                        bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                        w="100%"
                        pos="relative"
                        justify="center"
                        align="center"
                        borderRadius="7px"
                        h="50px"
                        cursor="pointer"
                      >
                        <VStack h="100%" align="center" justify="center">
                          <Text
                            fontSize="13px"
                            color="#33343D"
                            opacity="50%"
                            fontWeight="500"
                            cursor="pointer"
                          >
                            Upload Photo
                          </Text>
                          <Field
                            className="upload-input-hidden"
                            name="signature"
                            onChange={(event) => {
                              setSignatureErr("");
                              setSignatureName("");
                              if (event.currentTarget.files[0].size > 2000000) {
                                setSignatureErr(
                                  "Signature size cannot be more than 2mb"
                                );
                                return;
                              }
                              setSignature(event.currentTarget.files[0]);
                              setSignatureName(
                                event.currentTarget.files[0].name
                              );
                            }}
                            type="file"
                            accept="application/pdf,image/png,image/jpeg,image/jpg"
                          />
                        </VStack>
                      </Flex>
                      {signatureName && (
                        <Text fontSize="12px" color="#540A45" fontWeight={700}>
                          {signatureName}
                        </Text>
                      )}
                      {signatureErr && (
                        <Text color="red" fontSize="11px">
                          {!signatureName && signatureErr}
                        </Text>
                      )}
                    </Box>
                    <Box w={["100%", "50%"]}>
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="0.8px"
                      >
                        <label htmlFor="date">Date</label>
                      </Text>
                      <Field
                        className="form-input"
                        name="date"
                        value={new Date().toISOString().substr(0, 10)}
                        placeholder="DD/MM/YYYY"
                        type="date"
                      />
                      <Text color="red" fontSize="11px">
                        <ErrorMessage name="date" />
                      </Text>
                    </Box>
                  </Stack>

                  <Divider mt="30px" height="0.4px" bg="#5B2E4F" width="100%" />
                  <HStack justify="flex-end" spacing={3} mt="20px">
                    <Button
                      bg="white"
                      borderRadius="6px"
                      fontSize="12px"
                      fontWeight="500"
                      color="#5B2E4F"
                      border="1px solid #5B2E4F"
                      p={["8px 32px", "13px 52px"]}
                      _hover={{
                        color: "white",
                        border: "1px solid #5B2E4F",
                        bg: "#5B2E4F",
                      }}
                      onClick={goBack}
                    >
                      BACK
                    </Button>

                    {/* {
                                    isLoading ?
                                            <Button type="submit" bg="#5B2E4F" borderRadius="6px" fontSize="12px" fontWeight="500" color="white" p="13px 52px" _hover={{ color: "#5B2E4F", border: "1px solid #5B2E4F", bg: "white" }} isLoading
                                                loadingText='Submitting'>
                                                SUBMIT
                                            </Button>
                                            :
                                            <Button type="submit" bg="#5B2E4F" borderRadius="6px" fontSize="12px" fontWeight="500" color="white" p="13px 52px" _hover={{ color: "#5B2E4F", border: "1px solid #5B2E4F", bg: "white" }}>
                                                SUBMIT
                                            </Button>
                                   } */}

                    <Button
                      type="submit"
                      bg="#5B2E4F"
                      borderRadius="6px"
                      fontSize="12px"
                      fontWeight="500"
                      color="white"
                      p="13px 52px"
                      _hover={{
                        color: "#5B2E4F",
                        border: "1px solid #5B2E4F",
                        bg: "white",
                      }}
                    >
                      NEXT
                    </Button>
                  </HStack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Flex>
  );
};

export default ConvenantForm;

//8292411099
