import {
  Flex,
  Img,
  Text,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import React from "react";
import logo from "../../assets/HerVest Logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetState } from "../../features/loanSlice";

const Navbar = () => {
  const location = useLocation();

  const currentRoute = location.pathname;

  return (
    <>
      <Flex justify="space-between">
        <Link to="/home">
          <Img src={logo} objectFit="contain" w={["50%", "100%"]} />
        </Link>
        <HStack>
          {currentRoute !== "/" && (
            <>
              <AlertDialogExample text="back to home" />
            </>
          )}
        </HStack>
      </Flex>
    </>
  );
};

export default Navbar;

export function AlertDialogExample({ text }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = () => {
    onClose();
    dispatch(resetState());
    navigate("/");
  };

  return (
    <>
      <Text cursor="pointer" fontSize="14px" color="#5B2E4F" onClick={onOpen}>
        {text}
      </Text>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Go back
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You will lose all the progress that you've made so
              far.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={goBack} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
