import { API_BASE_URL } from "./apiConstants";
import axios from "axios";
import toast from "react-hot-toast";

const loginUser = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/account/login`,
      payload,
      config
    );
    return data.data;
  } catch (error) {
    toast.error("Invalid login credentials. Please try again.");
    return null;
  }
};

const fetchUserInfo = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${API_BASE_URL}/user/get_user_details`,
      config
    );
    console.log(data, "data BACK USERRRRRR");
    return data.data;
  } catch (error) {
    toast.error("Invalid Access Token. Please try again.");
    return null;
  }
};

const authService = {
  loginUser,
  fetchUserInfo,
};

export default authService;
