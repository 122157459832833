import {
  Box,
  Flex,
  Text,
  Divider,
  HStack,
  VStack,
  Button,
  Img,
  Input,
  useToast,
} from "@chakra-ui/react";
import "@smile_identity/smart-camera-web";
import React, { useEffect, useState, useCallback } from "react";
// import { Formik, Field, Form, ErrorMessage } from 'formik'
// import * as Yup from 'yup'
import logo from "../assets/HerVest Logo.svg";
import signatory from "../assets/signatory.svg";
// import { useDispatch, useSelector } from 'react-redux'
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { API_BASE_URL } from "../services/apiConstants";

// import { updateField } from '../../features/loanSlice'

const AcceptOffer = ({ setCurrentScreen }) => {
  const toast = useToast();
  const [loanDetails, SetLoanDetails] = useState();
  const [queryParameters] = useSearchParams();

  const getDetails = useCallback(async () => {
    const pathname = window.location.pathname.slice(14);

    try {
      const resp = await axios.get(
        `${API_BASE_URL}/loans/requests/${pathname}`
      );
      SetLoanDetails(resp?.data?.data);
      // setData(resp?.data?.data)

      toast({
        title: "Loan Request",
        description: resp?.data?.message,
        position: "top-left",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      return resp?.data;
    } catch (error) {
      toast({
        title: "Application not found",
        description:
          "The ID doesn't exist, please start a new loan application.",
        position: "top-left",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
    }
  }, [toast]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const loan_acceptance = async () => {
    const pathname = window.location.pathname.slice(14);

    try {
      const bodyAccept = {
        token: queryParameters.get("token"),
      };

      const resp = await axios.post(
        `${API_BASE_URL}/loans/offers/${pathname}/customer/approve`,
        bodyAccept
      );

      toast({
        title: "Loan Acceptance",
        description: resp?.data?.message,
        position: "top-left",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      return resp?.data;
    } catch (error) {
      toast({
        title: "Acceptance Error",
        description: error.response.data.message,
        position: "top-left",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } finally {
    }
  };

  const loan_reject = async () => {
    const pathname = window.location.pathname.slice(14);

    try {
      const bodyAccept = {
        token: queryParameters.get("token"),
      };

      const resp = await axios.post(
        `${API_BASE_URL}/loans/offers/${pathname}/customer/decline`,
        bodyAccept
      );

      toast({
        title: "Loan Decline",
        description: resp?.data?.message,
        position: "top-left",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      return resp?.data;
    } catch (error) {
      toast({
        title: "Decline Error",
        description: error.response.data.message,
        position: "top-left",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } finally {
    }
  };

  return (
    <Flex mt={"6%"} mb={"5%"} justify="center">
      <Box
        w={["100%", "80%", "60%"]}
        pb={"3%"}
        boxShadow="0px 4px 12px 0px #D7D7D740"
        border="0.5px solid #EFEAED"
        borderRadius="10px"
      >
        <Img
          mt={"5%"}
          ml={"25%"}
          src={logo}
          objectFit="contain"
          w={["10%", "50%"]}
        />

        <Box p={["10px", "20px"]}>
          <Text
            color="#33343D"
            fontWeight="500"
            fontSize={["12px", "14px"]}
            lineHeight="21px"
            align="justify"
            fontFamily="SatoshiBold"
          >
            Dear {loanDetails?.first_name} {loanDetails?.last_name}
          </Text>
        </Box>

        <Text
          align={"center"}
          p="20px"
          fontSize={"17px"}
          fontWeight={"800"}
          color="#33343D"
        >
          OFFER OF CREDIT FACILITY
        </Text>

        <Box p={["10px", "20px"]}>
          <Text
            color="#33343D"
            fontWeight="500"
            fontSize={["12px", "14px"]}
            lineHeight="21px"
            align="justify"
            fontFamily="SatoshiBold"
          >
            We are pleased to advise you that the Management of HerVest for
            Women limited has approved your request for a loan facility under
            the following terms and conditions:
          </Text>
        </Box>

        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          OTHER CONDITIONS:
        </Text>

        <Divider
          color="#5B2E4F"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />

        <Box p={["10px", "20px"]}>
          <VStack spacing={6} align="baseline">
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              a) You hereby indemnify HerVest against any loss that might be
              incurred by them as a result of any irregularity or incompleteness
              in the information contained in any documents submitted by you.
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              b) No change in the business operations and conditions (financial
              or otherwise) or other prospects shall affect the full repayment
              of the facility at maturity.
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              fontFamily="SatoshiBold"
              align="justify"
            >
              c) This facility shall be utilized strictly for the purpose stated
              above and on no condition(s) should it be utilized for any other
              purpose.
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              d) Funds received into the account when the principal and/or
              interest are past due, will be applied first to the overdue
              interest before the outstanding principal amount.
            </Text>

            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              e) The approval and disbursement of loans is at the sole
              discretion of the HerVest based on its internal risk assessment.
            </Text>
          </VStack>
        </Box>

        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          VALIDITY CLAUSE:
        </Text>
        <Divider
          color="#5B2E4F"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />

        <Box p={["10px", "20px"]}>
          <VStack spacing={3} align="baseline">
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              This offer will lapse at the instance of HerVest if not accepted
              within 3 (three) days from the date of this letter.
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              If the terms and conditions of this facility are acceptable to
              you, kindly sign a copy of this offer letter and return same to
              us.
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              fontFamily="SatoshiBold"
              align="justify"
            >
              We thank you for your continued patronage and look forward to a
              more fruitful banking relationship.
            </Text>

            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="17px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              Yours Faithfully,
            </Text>

            <Text
              color="#33343D"
              fontWeight="800"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              For: HerVest for Women Limited.
            </Text>
          </VStack>
        </Box>

        <Box p={["10px", "20px"]}>
          <VStack spacing={5} align="baseline">
            <Text
              color="#33343D"
              fontWeight="600"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              Authorised Signatory
            </Text>

            <Img
              ml={"5%"}
              src={signatory}
              objectFit="contain"
              w={["20%", "70%"]}
            />
          </VStack>
        </Box>

        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          ACCEPTANCE:
        </Text>

        <Divider
          color="#5B2E4F"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />

        <Box p={["10px", "20px"]}>
          <VStack spacing={3} align="baseline">
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              Please signify your acceptance of the above terms and conditions
              by signing the acceptance column below and returning the second
              copy of this letter to the HerVest.
            </Text>

            <Box w={["100%", "50%"]}>
              <Text
                color="#33343D"
                fontWeight="500"
                fontSize="13px"
                opacity="0.8px"
              >
                <label htmlFor="borrowerFullName">Borrower's Full Name</label>
              </Text>
            </Box>

            {/* <Box> */}
            <Input minW={"70%"} className="form-input" onChange={(e) => {}} />
            {/* </Box> */}

            {/* <Text color="#33343D" fontWeight="500" fontSize={["12px", "14px"]} lineHeight="21px" align="justify" fontFamily="SatoshiBold">
                            If the terms and conditions of this facility are acceptable to you, kindly sign a copy of this offer letter and return same to us.
                        </Text> */}
          </VStack>
        </Box>

        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          ILLITERATE JURAT
        </Text>

        <Divider
          color="#5B2E4F"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />

        <Box p={["10px", "20px"]}>
          <VStack spacing={3} align="baseline">
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize={["12px", "14px"]}
              lineHeight="21px"
              align="justify"
              fontFamily="SatoshiBold"
            >
              The contents of this Offer Letter haven been first read and
              interpreted to the Borrower by ……………………………………. (Name of
              interpreter) in language and when he/she appeared to have
              perfectly understood and approved the contents, appended his/her
              signature or affixed his/her right thumb as above.
            </Text>

            <Box w={["100%", "50%"]}>
              <Text
                color="#33343D"
                fontWeight="500"
                fontSize="13px"
                opacity="0.8px"
              >
                <label htmlFor="borrowerFullName">Borrower's Full Name</label>
              </Text>
            </Box>

            <Input minW={"70%"} className="form-input" onChange={(e) => {}} />

            {/* <Text color="#33343D" fontWeight="500" fontSize={["12px", "14px"]} lineHeight="21px" align="justify" fontFamily="SatoshiBold">
                            If the terms and conditions of this facility are acceptable to you, kindly sign a copy of this offer letter and return same to us.
                        </Text> */}
          </VStack>
        </Box>

        <Divider
          color="#5B2E4F"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />

        <HStack justify="flex-end" mt="20px" ml="5px" mr="5px">
          <Button
            onClick={() => loan_reject()}
            type="submit"
            bg="white"
            border="1px solid #5B2E4F"
            borderRadius="6px"
            fontSize="12px"
            fontWeight="500"
            color="#5B2E4F"
            p="13px 52px"
          >
            DECLINE
          </Button>
          <Button
            onClick={() => loan_acceptance()}
            type="submit"
            bg="#5B2E4F"
            borderRadius="6px"
            fontSize="12px"
            fontWeight="500"
            color="white"
            p="13px 52px"
            _hover={{
              color: "#5B2E4F",
              border: "1px solid #5B2E4F",
              bg: "white",
            }}
          >
            ACCEPT
          </Button>
        </HStack>
      </Box>
    </Flex>
  );
};

export default AcceptOffer;

//8292411099
