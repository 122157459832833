import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    components: {
        Radio: {
            // Customize the style of the radio component here
            baseStyle: {
                color: '#5B2E4F',
              
                borderColor: '#666666',
                control: {
                    
                    borderColor: '#666666',
                    opacity: "0.85",
                    _checked: {
                        color: '#5B2E4F', 
                        bg: 'white',
                        borderColor: '#5B2E4F',
                         _hover: {
                            bg: 'white',
                            borderColor: '#5B2E4F',
                        },
                    },
                    _hover: {
                        bg: 'white', 
                        borderColor: '#5B2E4F',
                    },
                    bg: 'white',
                },
                _hover: {
                   
                    bg: 'white', 
                    borderColor: 'white', 
                    
                },
            },
        },
    },
});

export default theme;
