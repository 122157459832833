import {
  Box,
  Flex,
  Text,
  Divider,
  HStack,
  VStack,
  Img,
  Button,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { guarantorForm } from '../../features/loanSlice'
import flag from "../../assets/flag.svg";
import { IoIosArrowDown } from "react-icons/io";
import Navbar from "../Others/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../services/apiConstants";
import loanService from "../../services/loanAction";
import LoadingScreen from "../block-components/loader/LoadingScreen";
import { ScrollToFieldError } from "../../services/utils/helpers";
import { Player } from "@lottiefiles/react-lottie-player";
import Success from "../../assets/lottie/Success.json";
import Error from "../../assets/lottie/Error.json";
import { Link } from "react-router-dom";
import FileUploadField from "../block-components/Input/FileUpload";
import { knownYears } from "../../services/utils/constants";
import { nigeriaStates } from "../../services/utils/constants";
import { getBase64 } from "../../services/utils/helpers";

//1142160206
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

const GuarantorForm = () => {
  const { userid } = useParams();
  const [loanData, setLoanData] = useState();
  const [guarantorCompleted, setGuarantorCompleted] = useState(false);
  const [processing, setProcessing] = useState(true);

  const toast = useToast();
  const allLoanData = useSelector((state) => state.loanData);

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(allLoanData?.idName || "");
  const [utility, setUtility] = useState(allLoanData?.utilityName || "");

  const [idFile, setIdFile] = useState(
    allLoanData?.government_id ? allLoanData?.government_id : ""
  );
  const [utilityFile, setUtilityFile] = useState(
    allLoanData?.utility_bill ? allLoanData?.utility_bill : ""
  );

  const [idError, setIdError] = useState("");
  const [utilityError, setUtilityError] = useState("");
  const [bankStatementFile, setBankStatementFile] = useState(
    allLoanData?.bank_statement ? allLoanData?.bank_statement : ""
  );

  const [passport, setPassport] = useState();
  const [passportBase64, setPassportBase64] = useState("");
  const [bankStatement, setBankStatement] = useState();

  //store the files object
  const [passportFile, setPassportFile] = useState();
  const [passportError, setPassportError] = useState("");
  const [bankStatementError, setBankStatementError] = useState("");

  const uploadPassportHandler = async (file) => {
    setPassport("");
    setPassportFile("");
    setPassportError("");

    if (file.size > 2000000) {
      setPassportError("Passport size cannot be more than 2mb");
      return;
    }

    const converted = await getBase64(file);
    setPassportBase64(converted);
    setPassport(file.name);
    setPassportFile(file);
  };

  const uploadBankStatementHandler = async (file) => {
    setBankStatementError("");
    setBankStatement("");
    setBankStatementFile("");
    if (file.size > 2000000) {
      setBankStatementError("Bank statement size cannot be more than 2mb");
      return;
    }

    if (file) {
      setBankStatement(file.name);
      setBankStatementFile(file);
    }
  };

  const cleanAmount = (value) => {
    return parseInt(value?.toString()?.replace(/,/g, ""));
  };

  const uploadUtilityHandler = (file) => {
    setUtilityError("");
    setUtility("");
    setUtilityFile("");
    // const file = e.target.files[0];
    if (file.size > 2000000) {
      setUtilityError("Utility bill size cannot be more than 2mb");
      return;
    }

    if (file) {
      setUtility(file.name);
      setUtilityFile(file);
    }
  };

  const uploadIdHandler = (file) => {
    setIdError("");
    setId("");
    setIdFile("");
    if (file.size > 2000000) {
      setIdError("ID size cannot be more than 2mb");
      return;
    }
    if (file) {
      setId(file.name);
      setIdFile(file);
    }
  };

  const initialvalues = {
    name: "",
    gender: "",
    maritalStatus: "",
    dob: "",
    homeAddress: "",
    phoneNumber: "",
    email: "",
    businessName: "",
    businessAddress: "",
    businessPhone: "",
    income: "",
    businessYears: "",
    borrowersName: loanData?.first_name + " " + loanData?.last_name,
    relationship: "",
    yearsKnown: "",
    amount: loanData?.amount,
    tenure: loanData?.tenure,
  };

  const validationSchemaData = {
    name: Yup.string()
      .max(60, "Must be 60 characters or less")
      .required("Required"),
    dob: Yup.date().required("required"),
    gender: Yup.string().required("required"),
    maritalStatus: Yup.string().required("required"),
    homeAddress: Yup.string().required("required"),
    email: Yup.string()
      .required("Email is required")
      .matches(emailRegex, "Invalid email address"),
    businessName: Yup.string()
      .max(60, "Must be 60 characters or less")
      .required("Required"),
    businessAddress: Yup.string().required("required"),
    phoneNumber: Yup.number()
      .required("Phone number is required")
      .test(
        "len",
        "Phone number should be 11 characters",
        (val) => val.toString().length === 10
      ),
    businessPhone: Yup.number()
      .required("Phone number is required")
      .test(
        "len",
        "Phone number should be 11 characters",
        (val) => val.toString().length === 10
      ),
    income: Yup.string().required("required"),
    businessYears: Yup.string().required("required"),
    borrowersName: Yup.string().required("required"),
    relationship: Yup.string().required("required"),
    yearsKnown: Yup.string().required("required"),
    tenure: Yup.string().required("required"),
    amount: Yup.string().required("Amount is required"),
  };

  const submitBtn = async (values, { resetForm }) => {
    setIdError("");
    setUtilityError("");
    if (!(id && utility)) {
      if (!id) {
        setIdError("Government id is required");
      } else {
        setIdError("");
      }
      if (!utility) {
        setUtilityError("Utility bill is required");
      } else setUtilityError("");
      return;
    }

    if (!userid) {
      toast({
        title: "UserId is not defined",
        description: "Please check your email for the guarantor's link",
        position: "top-left",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("gender", values.gender);
    formData.append("marital_status", values.maritalStatus);
    formData.append("date_of_birth", values.dob);
    formData.append("address", values.homeAddress);
    formData.append("phone", values.phoneNumber);
    formData.append("email", values.email);
    formData.append("business_name", values.businessAddress);
    formData.append("business_address", values.name);
    formData.append("business_phone", values.businessPhone);
    formData.append("years_in_business", values.businessYears);
    formData.append("monthly_net_income", cleanAmount(values.income));
    formData.append("borrower_name", values.borrowersName);
    formData.append("borrower_relationship", values.relationship);
    formData.append("borrower_years_known", values.yearsKnown);
    formData.append("borrower_loan_amount", cleanAmount(values.amount));
    formData.append("borrower_loan_tenure", values.tenure);
    formData.append("nature_of_business", values.natureOfBusiness);
    formData.append("state_of_origin", values.state_of_origin);
    formData.append("loan_request_id", loanData?.request_id);
    formData.append("bvn", values.bvn);
    formData.append("bank_statement", bankStatementFile);
    formData.append("utility_bill", utilityFile);
    formData.append("passport", passportFile);
    formData.append("government_id", idFile);

    setLoading(true);
    try {
      // eslint-disable-next-line

      const resp = await axios.post(
        `${API_BASE_URL}/loans/requests/${userid}/guarantor`,
        formData
      );
      if (resp) {
        toast({
          title: "Successfully Submission",
          description: "Guarantor has been submitted successfully",
          position: "top-left",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
        setGuarantorCompleted(true);
      }
    } catch (error) {
      toast({
        title: "Notification",
        description:
          error?.response?.data?.message ||
          "An error occurred. Please try again",
        position: "top-left",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const formatAmount = (value) => {
    const cleanedValue = value?.toString()?.replace(/,/g, "");
    const formattedValue = cleanedValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  };

  useEffect(() => {
    const getLoanInfo = async () => {
      setProcessing(true);
      const res = await loanService.getLoanApplicationDetails(userid);
      if (res) {
        setLoanData(res);
      }
      setProcessing(false);
    };
    getLoanInfo();
  }, [userid]);

  return (
    <>
      {processing && <LoadingScreen />}

      {!loanData && !processing && (
        <Box h={"80"} p={["20px 10px", "20px 30px"]}>
          <Flex justify="center" mt="50px" align="center">
            <Box
              w={["100%", "80%", "60%"]}
              boxShadow="0px 4px 12px 0px rgba(197, 197, 197, 0.25)"
              borderRadius="35px"
              border="0.5px solid #EFEAED"
              p={["20px 30px", "25px 35px", "45px 55px 45px 55px"]}
            >
              <Text
                p="10px 10px 20px 10px"
                fontSize={["18px", "22px"]}
                fontWeight={["500", "700"]}
                color="#540A45"
                fontFamily="SatoshiBold"
              >
                Error!
              </Text>

              <Box
                w="100%"
                h="300px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Player
                  style={{ width: "50%", height: "100%" }}
                  src={Error}
                  loop
                  autoplay
                />
              </Box>

              <Text>
                <Text
                  fontSize={["16px", "18px"]}
                  fontWeight={["400", "500"]}
                  color="#5B2E4F"
                  mb={["30px", "50px"]}
                >
                  <Text>
                    We couldn't retrive the loan details from the Request ID
                    provided. Please check the link and try again
                  </Text>
                </Text>
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
      {loanData && guarantorCompleted && (
        <Box h={"80"} p={["20px 10px", "20px 30px"]}>
          <Navbar />

          <Flex justify="center" mt="50px" align="center">
            <Box
              w={["100%", "80%", "60%"]}
              boxShadow="0px 4px 12px 0px rgba(197, 197, 197, 0.25)"
              borderRadius="35px"
              border="0.5px solid #EFEAED"
              p={["20px 30px", "25px 35px", "45px 55px 45px 55px"]}
            >
              <Text
                p="10px 10px 20px 10px"
                fontSize={["18px", "22px"]}
                fontWeight={["500", "700"]}
                color="#540A45"
                fontFamily="SatoshiBold"
              >
                Success!
              </Text>

              <Box
                w="100%"
                h="300px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Player
                  style={{ width: "80%", height: "100%" }}
                  src={Success}
                  loop
                  autoplay
                />
              </Box>

              <Text>
                <Text
                  fontSize={["16px", "18px"]}
                  fontWeight={["400", "500"]}
                  color="#5B2E4F"
                  mb={["30px", "50px"]}
                >
                  <Text>
                    Thanks for completing the guarantor’s form. We will notify{" "}
                    {loanData?.first_name} that you have completed the form.
                  </Text>
                </Text>
              </Text>

              <Flex justify="end" align="center" flexWrap="wrap" w="100%">
                <Box>
                  <Button color={"#fff"} bg={"#5B2E4F"}>
                    DOWNLOAD THE HERVEST APP TODAY
                  </Button>
                  <Link to="https://hervestng.app.link">
                    <Flex py="20px">
                      <Box pr={5}>
                        <Img src="/google.svg" />
                      </Box>
                      <Img src="/apple.svg" />
                    </Flex>
                  </Link>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      )}
      {loanData && !guarantorCompleted && (
        <Box p={["20px 10px", "20px 30px"]}>
          <Navbar />
          <Text
            m="40px 0"
            color="#540A45"
            fontSize={["14px", "21px"]}
            fontWeight="700"
            fontFamily="SatoshiBolder"
            lineHeight="28.35px"
            textAlign={["left", "center"]}
          >
            LOAN APPLICATION FORM FOR GUARANTOR's
          </Text>
          <Flex justify="center">
            <Box
              w={["100%", "80%", "60%"]}
              boxShadow="0px 4px 12px 0px #D7D7D740"
              border="0.5px solid #EFEAED"
              borderRadius="10px"
            >
              <Text
                p="20px"
                fontSize={["12px", "14px"]}
                fontWeight={["500", "700"]}
                color="#33343D"
              >
                GUARANTOR's INFORMATION
              </Text>
              <Divider
                color="#666666"
                opacity="85%"
                height="2px"
                width="100%"
                borderBottom="2px color=1px solid #5B2E4F1F"
              />
              <Box p={["10px", "20px"]}>
                <Formik
                  initialValues={initialvalues}
                  validationSchema={Yup.object(validationSchemaData)}
                  onSubmit={submitBtn}
                >
                  {({ values, errors, touched, handleChange }) => {
                    return (
                      <Form>
                        <ScrollToFieldError />
                        <VStack spacing={8}>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="name">Name*</label>
                              </Text>
                              <Field
                                className="form-input"
                                name="name"
                                placeholder="First Name"
                                type="text"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="name" />
                              </Text>
                            </Box>
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="gender">Sex*</label>
                              </Text>
                              <Field
                                className="form-input"
                                as="select"
                                name="gender"
                                placeholder="gender"
                              >
                                <option className="optionFields" value="">
                                  Select An Option
                                </option>
                                <option className="optionFields" value="male">
                                  Male
                                </option>
                                <option className="optionFields" value="female">
                                  Female
                                </option>
                              </Field>
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="gender" />
                              </Text>
                            </Box>
                          </Stack>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="maritalStatus">
                                  Marital Status*
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                as="select"
                                name="maritalStatus"
                                placeholder="marital status"
                              >
                                <option className="optionFields">
                                  Select An Option
                                </option>
                                <option className="optionFields" value="single">
                                  Single
                                </option>
                                <option
                                  className="optionFields"
                                  value="married"
                                >
                                  Married
                                </option>
                              </Field>
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="maritalStatus" />
                              </Text>
                            </Box>
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="dob">Date of Birth*</label>
                              </Text>
                              <Field
                                className="form-input"
                                name="dob"
                                placeholder="DD/MM/YYYY"
                                type="date"
                                max="2006-01-01"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="dob" />
                              </Text>
                            </Box>
                          </Stack>
                          <Box w="100%">
                            <Text
                              color="#33343D"
                              fontWeight="500"
                              fontSize="13px"
                              opacity="80%"
                            >
                              <label htmlFor="homeAddress">Home Address*</label>
                            </Text>
                            <Field
                              as="textarea"
                              className="form-textarea"
                              name="homeAddress"
                              placeholder="Enter Address"
                              type="text"
                            />
                            <Text color="red" fontSize="11px">
                              <ErrorMessage name="homeAddress" />
                            </Text>
                          </Box>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="phoneNumber">
                                  Phone Number*
                                </label>
                              </Text>
                              <HStack
                                width="100%"
                                p="15px 10px"
                                background="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                border="0.17px solid rgba(91, 46, 79, 1)"
                                borderRadius="6px"
                              >
                                <HStack spacing={1} pl="5px" mr="10px">
                                  <Img
                                    src={flag}
                                    borderRadius="5px"
                                    objectFit="contain"
                                  />
                                  <Text
                                    color="#33343D"
                                    fontSize="12px"
                                    fontWeight="500"
                                    opacity="50%"
                                  >
                                    +234
                                  </Text>
                                  <Box color="#848481">
                                    <IoIosArrowDown />
                                  </Box>
                                </HStack>
                                <Field
                                  className="form-input-phonenumber"
                                  name="phoneNumber"
                                  placeholder="Phone Number"
                                  type="number"
                                />
                              </HStack>
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="phoneNumber" />
                              </Text>
                            </Box>
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="email">Email Address*</label>
                              </Text>
                              <Field
                                className="form-input"
                                name="email"
                                placeholder="Enter Mail"
                                type="text"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="email" />
                              </Text>
                            </Box>
                          </Stack>

                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="email">
                                  BVN (Bank Verification Number)*
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                name="bvn"
                                placeholder="Enter BVN"
                                type="number"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="bvn" />
                              </Text>
                            </Box>
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                              >
                                <label htmlFor="stateOfResidence">
                                  State Of Origin*
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                as="select"
                                name="state_of_origin"
                              >
                                <option className="state_of_origin" value="">
                                  Select An Option
                                </option>
                                {nigeriaStates?.map((item) => {
                                  return (
                                    <option
                                      className="state_of_origin"
                                      value={item}
                                    >
                                      {item}
                                    </option>
                                  );
                                })}
                              </Field>
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="stateOfResidence" />
                              </Text>
                            </Box>
                          </Stack>
                        </VStack>
                        <Divider
                          mt="30px"
                          height="0.4px"
                          bg="#5B2E4F"
                          width="100%"
                        />
                        <Text
                          p="20px 0px"
                          fontSize={["12px", "14px"]}
                          fontWeight={["500", "700"]}
                          color="#33343D"
                          textTransform="uppercase"
                        >
                          Business information
                        </Text>
                        <VStack spacing={8}>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="businessName">
                                  Business Name*
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                name="businessName"
                                placeholder="Business Name"
                                type="text"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="businessName" />
                              </Text>
                            </Box>
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="businessAddress">
                                  Business Address*
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                name="businessAddress"
                                placeholder="Business Address"
                                type="text"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="businessAddress" />
                              </Text>
                            </Box>
                          </Stack>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="businessPhone">
                                  Phone Number*
                                </label>
                              </Text>
                              <HStack
                                width="100%"
                                p="15px 10px"
                                background="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                border="0.17px solid rgba(91, 46, 79, 1)"
                                borderRadius="6px"
                              >
                                <HStack spacing={1} pl="5px" mr="10px">
                                  <Img
                                    src={flag}
                                    borderRadius="5px"
                                    objectFit="contain"
                                  />
                                  <Text
                                    color="#33343D"
                                    fontSize="12px"
                                    fontWeight="500"
                                    opacity="50%"
                                  >
                                    +234
                                  </Text>
                                  <Box color="#848481">
                                    <IoIosArrowDown />
                                  </Box>
                                </HStack>
                                <Field
                                  className="form-input-phonenumber"
                                  name="businessPhone"
                                  placeholder="Phone Number"
                                  type="number"
                                />
                              </HStack>
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="businessPhone" />
                              </Text>
                            </Box>
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                              >
                                <label htmlFor="income">
                                  Monthly net income/Net Worth*
                                </label>
                              </Text>

                              <input
                                className="form-input"
                                name="income"
                                placeholder="Enter Income"
                                type="text"
                                value={formatAmount(values.income)}
                                onChange={handleChange}
                              />

                              {errors.income && touched.income && (
                                <div style={{ color: "red" }}>
                                  <Text color="red" fontSize="11px">
                                    {errors.income}
                                  </Text>
                                </div>
                              )}
                              <Text color="red" fontSize="11px"></Text>
                            </Box>
                          </Stack>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                              >
                                <label htmlFor="businessYears">
                                  Years in Business
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                as="select"
                                name="businessYears"
                              >
                                <option className="optionFields" value="">
                                  Select An Option
                                </option>
                                {Array.from(
                                  { length: 12 },
                                  (_, i) => i + 1
                                ).map((value) => {
                                  return (
                                    <option
                                      className="optionFields"
                                      value={value}
                                    >
                                      {value}
                                    </option>
                                  );
                                })}
                              </Field>
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="businessYears" />
                              </Text>
                            </Box>

                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="natureOfBusiness">
                                  Nature Of Business
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                name="natureOfBusiness"
                                placeholder="Nature of Business"
                                type="text"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="natureOfBusiness" />
                              </Text>
                            </Box>
                          </Stack>
                        </VStack>
                        <Divider
                          mt="30px"
                          height="0.4px"
                          bg="#5B2E4F"
                          width="100%"
                        />
                        <Text
                          p="20px 0px"
                          fontSize={["12px", "14px"]}
                          fontWeight={["500", "700"]}
                          color="#33343D"
                          textTransform="uppercase"
                        >
                          Borrower details
                        </Text>
                        <VStack spacing={8}>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="borrowersName">Name*</label>
                              </Text>
                              <Field
                                disabled
                                className="form-input"
                                name="borrowersName"
                                placeholder="Name"
                                type="text"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="borrowersName" />
                              </Text>
                            </Box>
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="80%"
                              >
                                <label htmlFor="relationship">
                                  Relationship to borrower*
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                name="relationship"
                                placeholder="relationship"
                                type="text"
                              />
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="relationship" />
                              </Text>
                            </Box>
                          </Stack>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                              >
                                <label
                                  htmlFor="yearsKnown
                                                    "
                                >
                                  How long have you known the borrower?*
                                </label>
                              </Text>
                              <Field
                                className="form-input"
                                as="select"
                                name="yearsKnown"
                              >
                                <option
                                  disabled
                                  className="optionFields"
                                  value=""
                                >
                                  Select An Option
                                </option>
                                {knownYears?.map((item) => {
                                  return (
                                    <option
                                      className="optionFields"
                                      value={item.value}
                                    >
                                      {item?.label}
                                    </option>
                                  );
                                })}
                              </Field>
                              <Text color="red" fontSize="11px">
                                <ErrorMessage name="yearsKnown" />
                              </Text>
                            </Box>
                            <Box w={["100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                              >
                                <label htmlFor="amount">
                                  Loan Amount Requested (Naira)*
                                </label>
                              </Text>
                              <input
                                className="form-input dis"
                                name="amount"
                                min={10000}
                                max={10000000}
                                placeholder="Enter Amount"
                                type="text"
                                disabled
                                value={`₦ ${formatAmount(values.amount)}`}
                                onChange={handleChange}
                              />

                              {errors.amount && touched.amount && (
                                <div style={{ color: "red" }}>
                                  <Text color="red" fontSize="11px">
                                    {errors.amount}
                                  </Text>
                                </div>
                              )}
                              <Text color="red" fontSize="11px"></Text>
                            </Box>
                          </Stack>
                          <Box w={["100%"]}>
                            <Text
                              color="#33343D"
                              fontWeight="500"
                              fontSize="13px"
                              opacity="0.8px"
                            >
                              <label htmlFor="tenure">Tenure (Months)</label>
                            </Text>
                            <Field
                              disabled
                              className="form-input disabledInput"
                              as="select"
                              name="tenure"
                            >
                              <option className="optionFields" value="">
                                Select An Option
                              </option>
                              {Array.from({ length: 12 }, (_, i) => i + 1).map(
                                (value) => {
                                  return (
                                    <option
                                      className="optionFields"
                                      value={value}
                                    >
                                      {value}
                                    </option>
                                  );
                                }
                              )}
                            </Field>
                            <Text color="red" fontSize="11px">
                              <ErrorMessage name="tenure" />
                            </Text>
                          </Box>
                        </VStack>
                        <Divider
                          mt="50px"
                          color="#5B2E4F"
                          opacity="85%"
                          height="2px"
                          width="100%"
                          mb="20px"
                        />
                        <Text
                          color="#33343D"
                          fontSize="14px"
                          fontWeight="700"
                          fontFamily="SatoshiBold"
                          lineHeight="19px"
                        >
                          UPLOAD DOCUMENTS{" "}
                          <span style={{ fontWeight: "500" }}>
                            (upload format should can be in png, jpg, jpeg or
                            pdf max 2mb)
                          </span>
                        </Text>

                        <VStack
                          direction={["column", "row"]}
                          spacing={5}
                          mt="30px"
                        >
                          <Stack
                            direction={["column", "column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                          >
                            <Box w={["100%", "100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                                mb="5px"
                              >
                                <label
                                  htmlFor="utility"
                                  fontFamily="SatoshiBold"
                                >
                                  Utility Bill
                                </label>
                              </Text>

                              <FileUploadField
                                types={["PDF", "JPEG", "JPG", "PNG"]}
                                handleChange={(file) =>
                                  uploadUtilityHandler(file)
                                }
                                children={
                                  <>
                                    <Flex
                                      cursor="pointer"
                                      bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                      w="100%"
                                      pos="relative"
                                      justify="center"
                                      align="center"
                                      borderRadius="6px"
                                      h="100px"
                                    >
                                      <VStack
                                        h="100%"
                                        align="center"
                                        justify="center"
                                        cursor="pointer"
                                      >
                                        <Text
                                          fontSize="13px"
                                          color="#33343D"
                                          opacity="50%"
                                          fontWeight="500"
                                          cursor="pointer"
                                        >
                                          Upload Utility Bill
                                        </Text>
                                      </VStack>
                                    </Flex>

                                    {utility && (
                                      <Text
                                        fontSize="11px"
                                        color="#540A45"
                                        fontWeight={700}
                                      >
                                        {utility}
                                      </Text>
                                    )}
                                    {utilityError && (
                                      <Text color="red" fontSize="11px">
                                        {!utility && utilityError}
                                      </Text>
                                    )}
                                  </>
                                }
                              />
                            </Box>

                            <Box w={["100%", "100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                                mb="5px"
                              >
                                <label
                                  htmlFor="passport"
                                  fontFamily="SatoshiBold"
                                >
                                  PassPort Photograph
                                </label>
                              </Text>

                              <FileUploadField
                                types={["JPEG", "JPG", "PNG"]}
                                handleChange={(file) =>
                                  uploadPassportHandler(file)
                                }
                                children={
                                  <>
                                    <Flex
                                      cursor="pointer"
                                      bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                      w="100%"
                                      pos="relative"
                                      justify="center"
                                      align="center"
                                      borderRadius="6px"
                                      h="100px"
                                    >
                                      <VStack
                                        h="100%"
                                        align="center"
                                        justify="center"
                                      >
                                        {passportBase64 ? (
                                          <Box>
                                            <img
                                              style={styles.image}
                                              alt="img"
                                              src={passportBase64}
                                            />
                                          </Box>
                                        ) : (
                                          <Text
                                            cursor="pointer"
                                            fontSize="13px"
                                            color="#33343D"
                                            opacity="50%"
                                            fontWeight="500"
                                          >
                                            Upload Photo
                                          </Text>
                                        )}
                                      </VStack>
                                    </Flex>

                                    {passport && (
                                      <Text
                                        fontSize="12px"
                                        color="#540A45"
                                        fontWeight={700}
                                      >
                                        {passport}
                                      </Text>
                                    )}
                                    {passportError && (
                                      <Text color="red" fontSize="11px">
                                        {!passport && passportError}
                                      </Text>
                                    )}
                                  </>
                                }
                              />
                            </Box>

                            <Box w={["100%", "100%", "50%"]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                                mb="5px"
                              >
                                <label htmlFor="id">Government ID</label>
                              </Text>

                              <FileUploadField
                                types={["PDF", "JPEG", "JPG", "PNG"]}
                                handleChange={(file) => uploadIdHandler(file)}
                                children={
                                  <>
                                    <Flex
                                      cursor="pointer"
                                      bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                      w="100%"
                                      pos="relative"
                                      justify="center"
                                      align="center"
                                      borderRadius="6px"
                                      h="100px"
                                    >
                                      <VStack
                                        h="100%"
                                        align="center"
                                        justify="center"
                                      >
                                        <Text
                                          fontSize="13px"
                                          color="#33343D"
                                          opacity="50%"
                                          fontWeight="500"
                                        >
                                          upload ID
                                        </Text>
                                      </VStack>
                                    </Flex>
                                    {id && (
                                      <Text
                                        fontSize="12px"
                                        color="#540A45"
                                        fontWeight={700}
                                      >
                                        {id}
                                      </Text>
                                    )}
                                    {idError && (
                                      <Text color="red" fontSize="11px">
                                        {!id && idError}
                                      </Text>
                                    )}
                                  </>
                                }
                              />
                            </Box>
                          </Stack>

                          <Divider
                            mt="5px"
                            height="1px"
                            bg="#5B2E4F"
                            width="100%"
                          />

                          <Text
                            cursor="pointer"
                            fontSize="13px"
                            color="#5B2E4F"
                            fontWeight="900"
                          >
                            Kindly upload your bank statement
                          </Text>
                          <Stack
                            direction={["column", "row"]}
                            w="100%"
                            spacing={6}
                            justify="space-between"
                            h={["300px", "100px"]}
                          >
                            <Box w={["100%", "50%"]} h="50%" mt={[3, 0, 0]}>
                              <Text
                                color="#33343D"
                                fontWeight="500"
                                fontSize="13px"
                                opacity="0.8px"
                                mb="5px"
                              >
                                <label htmlFor="bankStatement">
                                  Upload Bank Statement (Last 6 Months)
                                </label>
                              </Text>
                              <FileUploadField
                                types={["PDF", "JPEG", "JPG", "PNG"]}
                                file={bankStatement}
                                handleChange={(file) =>
                                  uploadBankStatementHandler(file)
                                }
                                children={
                                  <>
                                    <Flex
                                      cursor="pointer"
                                      bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                      w="100%"
                                      pos="relative"
                                      justify="center"
                                      align="center"
                                      borderRadius="6px"
                                      h="24"
                                    >
                                      <VStack
                                        h="100%"
                                        align="center"
                                        justify="center"
                                      >
                                        <Text
                                          fontSize="13px"
                                          color="#33343D"
                                          opacity="50%"
                                          fontWeight="500"
                                        >
                                          Upload Bank Statement
                                        </Text>
                                      </VStack>
                                    </Flex>
                                    {bankStatement && (
                                      <Text
                                        fontSize="12px"
                                        color="#540A45"
                                        fontWeight={700}
                                      >
                                        {bankStatement}
                                      </Text>
                                    )}
                                    {bankStatementError && (
                                      <Text color="red" fontSize="11px">
                                        {!bankStatement && bankStatementError}
                                      </Text>
                                    )}
                                  </>
                                }
                              />
                            </Box>
                          </Stack>
                        </VStack>

                        <Flex justify="flex-end" mt="30px">
                          {loading ? (
                            <Button
                              type="submit"
                              bg="#5B2E4F"
                              borderRadius="6px"
                              fontSize="12px"
                              fontWeight="500"
                              color="white"
                              p="13px 52px"
                              _hover={{
                                color: "#5B2E4F",
                                border: "1px solid #5B2E4F",
                                bg: "white",
                              }}
                              isLoading
                            >
                              SUBMIT
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              bg="#5B2E4F"
                              borderRadius="6px"
                              fontSize="12px"
                              fontWeight="500"
                              color="white"
                              p="13px 52px"
                              _hover={{
                                color: "#5B2E4F",
                                border: "1px solid #5B2E4F",
                                bg: "white",
                              }}
                            >
                              SUBMIT
                            </Button>
                          )}
                        </Flex>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default GuarantorForm;

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  preview: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
  },
  image: { maxWidth: "200px", maxHeight: "100px" },
};
