import React from "react";
import {
  Button,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../features/loanSlice";

function IncompleteApplication({ isOpen, setIsOpen, setOpenLoanTypeModal }) {
  const allLoanData = useSelector((state) => state.loanData);
  const request_id = localStorage.getItem("request_id");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const startNewApplication = () => {
    dispatch(resetState());
    localStorage.removeItem("request_id");
    // localStorage.clear()
    setIsOpen(false);
  };

  const continueApplication = () => {
    setIsOpen(!isOpen);
    if (allLoanData.type) {
      navigate(`/request/${allLoanData.type}`);
      return;
    }

    setOpenLoanTypeModal(true);
  };

  return (
    <>
      <Modal
        isCentered
        onClose={() => setIsOpen(isOpen)}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={["12px", "16px"]}>
            {" "}
            Welcome Back {allLoanData.first_name}
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Box>
              <Text
                fontSize="15px"
                color="#5B2E4F"
                fontWeight="500"
                fontFamily="satoshiBold"
                mb="10px"
              >
                Would you love to continue with your incomplete{" "}
                {allLoanData.type} loan Application{" "}
                <span>{request_id && `with ID ${request_id}`}</span>
              </Text>
            </Box>
          </ModalBody>
          <Divider bg="#5B2E4F" opacity={"1px"} height="1px" width="100%" />
          <ModalFooter>
            <Button
              fontSize="12px"
              border="1px solid #5B2E4F"
              color="#5B2E4F"
              mr={3}
              onClick={() => startNewApplication()}
            >
              NO, START NEW APPLICATION
            </Button>
            <Button
              onClick={() => continueApplication()}
              bg="#5B2E4F"
              color="white"
              opacity={"1px"}
              fontSize="12px"
              fontWeight="500"
              fontFamily="satoshiBold"
              p="13px 35px"
            >
              YES, CONTINUE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default IncompleteApplication;
