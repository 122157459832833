import React from "react";
import {
  Button,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Divider,
  Flex,
  HStack,
} from "@chakra-ui/react";

function IneligibleApplicant({ isOpen, setIsOpen }) {
  return (
    <>
      <Modal
        isCentered
        onClose={() => setIsOpen(isOpen)}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <Flex justify="center" mt="50px" align="center">
            <Box
              w={["100%"]}
              boxShadow="0px 4px 12px 0px rgba(197, 197, 197, 0.25)"
              borderRadius="35px"
              border="0.5px solid #EFEAED"
              p={["20px 30px", "25px 35px", "45px 55px 45px 55px"]}
            >
              <Text
                p="10px 10px 20px 10px"
                fontSize={["18px", "22px"]}
                fontWeight={["500", "700"]}
                color="#540A45"
                fontFamily="SatoshiBold"
              >
                Thanks for your Interest in HerVest Loan
              </Text>

              <HStack mt="30px" spacing={[2, 3, 5]}>
                <Text>
                  Sorry you are not qualified for a loan. Eligible to only Lagos
                  residents
                </Text>
              </HStack>
              <Divider
                mt="30px"
                color="#666666"
                opacity="85%"
                height="2px"
                width="100%"
                borderBottom="2px color=1px solid #5B2E4F1F"
              />
              <Flex justify="flex-end" mt="10px">
                <Button
                  ml="5px"
                  type="submit"
                  bg="#5B2E4F"
                  borderRadius="6px"
                  fontSize="12px"
                  fontWeight="500"
                  color="white"
                  p="13px 52px"
                  _hover={{
                    color: "#5B2E4F",
                    border: "1px solid #5B2E4F",
                    bg: "white",
                  }}
                  onClick={() => {
                    window.location.replace("/");
                  }}
                >
                  Go Back
                </Button>
              </Flex>
            </Box>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}

export default IneligibleApplicant;
