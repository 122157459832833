import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../services/apiConstants";

let initialState = {
  type: "",
  isLoading: false,
  isEditing: false,
  isError: null,
  completed: false,
  allData: [],
  banks: [],
  first_name: "",
  last_name: "",
  alias: "",
  bvn: "",
  gender: "",
  marital_status: "",
  dob: "",
  phone: "",
  alternate_phone: "",
  email: "",
  address: "",
  cac: "",
  cacName: "",
  mono_authorisation_code: "",
  account_no: "",
  bank_name: "",
  account_name: "",
  bank_code: "",
  nin: "",
  verifiedNin: false,
  sub_type: "",
  tenure: 0,
  passportName: "",
  idName: "",
  utilityName: "",
  bankStatementName: "",
  passport: "",
  government_id: "",
  utility_bill: "",
  bank_statement: "",
  landmark: "",
  home_ownership: "",
  occupation: "",
  designation: "",
  employer: "",
  office_email: "",
  office_no: "",
  office_alternate_no: "",
  office_address: "",
  office_landmark: "",
  guarantor_name: "",
  guarantor_email: "",
  amount: 0,
  nok_name: "",
  nok_dob: "",
  nok_phone: "",
  nok_email: "",
  nok_place_of_work: "",
  nok_occupation: "",
  nok_office_phone: "",
  nok_office_address: "",
  nok_relationship: "",
  purpose: "",
  witness_name: "",
  witness_phone: "",
  witness_address: "",
  witness_relationship: "",
  witness_date: "",
  witness_dob: "",
  signature: "",
  signatureName: "",
  request_id: "",
  // is_bvn_verified: localStorage.getItem("verified_bvn") ? true : false,
  is_bvn_verified: false,
  is_nin_verified: false,
};

export const editLoan = createAsyncThunk(
  "data/editLoan",
  async (info, thunkAPI) => {
    const formData = new FormData();

    formData.append("first_name", info.first_name);
    formData.append("last_name", info.last_name);
    formData.append("alias", info.alias);
    formData.append("bvn", info.bvn);
    formData.append("gender", info.gender);
    formData.append("marital_status", info.marital_status);
    info.dob && formData.append("dob", info.dob);
    formData.append("phone", info.phone);
    formData.append("alternate_phone", info.alternate_phone);
    formData.append("email", info.email);
    formData.append("address", info.address);

    info.cac && info.cac && formData.append("cac", info.cac);
    formData.append("mono_authorisation_code", info.mono_authorisation_code);

    formData.append("account_no", info.account_no);
    formData.append("bank_name", info.bank_name);
    formData.append("account_name", info.account_name);
    formData.append("bank_code", info.bank_code);
    formData.append("nin", info.nin);
    info.passport && formData.append("passport", info.passport);
    info.government_id && formData.append("government_id", info.government_id);
    info.utility_bill && formData.append("utility_bill", info.utility_bill);
    info.bank_statement &&
      formData.append("bank_statement", info.bank_statement);

    formData.append("landmark", info.landmark);
    formData.append("home_ownership", info.home_ownership);
    formData.append("occupation", info.occupation);
    formData.append("designation", info.designation);
    formData.append("employer", info.employer);
    formData.append("office_email", info.office_email);
    formData.append("office_no", info.office_no);
    formData.append("office_alternate_no", info.office_alternate_no);
    formData.append("office_address", info.office_address);
    formData.append("office_landmark", info.landmark);
    info.amount && formData.append("amount", info.amount);

    formData.append("tenure", info.tenure);
    formData.append("nok_name", info.nok_name);
    info.nok_dob && formData.append("nok_dob", info.nok_dob);
    formData.append("nok_email", info.nok_email);
    formData.append("nok_phone", info.nok_phone);
    formData.append("nok_place_of_work", info.nok_place_of_work);
    formData.append("nok_occupation", info.nok_occupation);
    formData.append("nok_office_phone", info.nok_office_phone);
    formData.append("nok_office_address", info.nok_office_address);
    formData.append("nok_relationship", info.nok_relationship);
    formData.append("guarantor_name", info.guarantor_name);
    formData.append("guarantor_email", info.guarantor_email);
    formData.append("purpose", info.purpose);
    formData.append("witness_name", info.witness_name);
    formData.append("witness_phone", info.witness_phone);
    formData.append("witness_address", info.witness_address);
    formData.append("witness_relationship", info.witness_relationship);
    info.witness_date && formData.append("witness_date", info.witness_date);
    info.witness_dob && formData.append("witness_dob", info.witness_dob);
    info.signature && formData.append("signature", info.signature);
    formData.append("type", info.type.trim());
    formData.append("sub_type", info.sub_type);
    formData.append("completed", info.completed);
    formData.append("is_bvn_verified", info.is_bvn_verified);
    formData.append("is_nin_verified", info.is_nin_verified);

    try {
      const request_id = localStorage.getItem("request_id");
      const resp = await axios.post(
        `${API_BASE_URL}/loans/requests/${request_id}`,
        formData
      );
      if (info.completed === "true") {
        localStorage.removeItem("request_id");
        localStorage.clear();
      }
      return resp?.data;
    } catch (error) {
      window.alert(
        "SAVING FAILED : " +
          error.response.data.message.toString().toUpperCase()
      );
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const requestLoan = createAsyncThunk(
  "data/requestLoan",
  async (info, thunkAPI) => {
    const formData = new FormData();
    formData.append("first_name", info.first_name);
    formData.append("last_name", info.last_name);
    formData.append("alias", info.alias);
    formData.append("bvn", info.bvn);
    formData.append("gender", info.gender);
    formData.append("marital_status", info.marital_status);
    info.dob && formData.append("dob", info.dob);
    formData.append("phone", info.phone);
    formData.append("alternate_phone", info.alternate_phone);
    formData.append("email", info.email);
    formData.append("address", info.address);
    info.cac && info.cac && formData.append("cac", info.cac);
    formData.append("mono_authorisation_code", info.mono_authorisation_code);
    formData.append("account_no", info.account_no);
    formData.append("bank_name", info.bank_name);
    formData.append("account_name", info.account_name);
    formData.append("bank_code", info.bank_code);
    formData.append("nin", info.nin);
    info.passport && formData.append("passport", info.passport);
    info.government_id && formData.append("government_id", info.government_id);
    info.utility_bill && formData.append("utility_bill", info.utility_bill);
    info.bank_statement &&
      formData.append("bank_statement", info.bank_statement);
    formData.append("landmark", info.landmark);
    formData.append("home_ownership", info.home_ownership);
    formData.append("occupation", info.occupation);
    formData.append("designation", info.designation);
    formData.append("employer", info.employer);
    formData.append("office_email", info.office_email);
    formData.append("office_no", info.office_no);
    formData.append("office_alternate_no", info.office_alternate_no);
    formData.append("office_address", info.office_address);
    formData.append("office_landmark", info.landmark);
    info.amount && formData.append("amount", info.amount);
    formData.append("tenure", info.tenure);
    formData.append("nok_name", info.nok_name);
    info?.nok_dob && formData.append("nok_dob", info.nok_dob);
    formData.append("nok_email", info.nok_email);
    formData.append("nok_phone", info.nok_phone);
    formData.append("purpose", info.purpose);
    formData.append("nok_place_of_work", info.nok_place_of_work);
    formData.append("nok_occupation", info.nok_occupation);
    formData.append("nok_office_phone", info.nok_office_phone);
    formData.append("nok_office_address", info.nok_office_address);
    formData.append("nok_relationship", info.nok_relationship);
    formData.append("guarantor_name", info.guarantor_name);
    formData.append("guarantor_email", info.guarantor_email);
    formData.append("witness_name", info.witness_name);
    formData.append("witness_phone", info.witness_phone);
    formData.append("witness_address", info.witness_address);
    formData.append("witness_relationship", info.witness_relationship);
    info.witness_date && formData.append("witness_date", info.witness_date);
    info?.witness_dob && formData.append("witness_dob", info.witness_dob);
    info.signature && formData.append("signature", info.signature);
    formData.append("type", info.type.trim());
    formData.append("sub_type", info.sub_type);
    formData.append("completed", info.completed);
    formData.append("is_bvn_verified", info.is_bvn_verified);
    formData.append("is_nin_verified", info.is_nin_verified);

    try {
      const resp = await axios.post(`${API_BASE_URL}/loans/requests`, formData);
      if (info.completed !== "true") {
        localStorage.setItem("request_id", resp?.data?.data?.request_id);
      }
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const guarantorForm = createAsyncThunk(
  "data/guarantor",
  async (info, thunkAPI) => {
    const formData = new FormData();

    formData.append("name", info.name);
    formData.append("gender", info.gender);
    formData.append("marital_status", info.marital_status);
    formData.append("date_of_birth", info.date_of_birth);
    formData.append("address", info.address);
    formData.append("phone", info.phone);
    formData.append("email", info.email);
    formData.append("business_name", info.business_name);
    formData.append("business_address", info.business_address);
    formData.append("business_phone", info.business_phone);
    formData.append("years_in_business", info.years_in_business);
    formData.append("monthly_net_income", info.monthly_net_income);
    formData.append("borrower_name", info.borrower_name);
    formData.append("borrower_relationship", info.borrower_relationship);
    formData.append("borrower_years_known", info.borrower_years_known);
    formData.append("borrower_loan_amount", info.borrower_loan_amount);
    formData.append("borrower_loan_tenure", info.borrower_loan_tenure);

    // formData.append('utility_bill', fs.createReadStream('/Users/gboluwagaadeyemi/Downloads/favicon.png'));
    // formData.append('government_id', fs.createReadStream('/Users/gboluwagaadeyemi/Downloads/favicon.png'));

    // info.passport && formData.append('passport', info.passport);
    // info.government_id && formData.append('government_id', info.government_id);
    // info.utility_bill && formData.append('utility_bill', info.utility_bill);
    // info.bank_statement && formData.append('bank_statement', info.bank_statement);

    try {
      const resp = await axios.post(
        `${API_BASE_URL}/loans/requests/${info.loanid}/guarantor`,
        formData
      );

      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

const loanDataSlice = createSlice({
  name: "loanData",
  initialState,
  reducers: {
    updateField: (state, action) => {
      const data = action.payload;

      return { ...state, ...data };
    },
    resetState: () => initialState,
  },
  extraReducers: {
    [requestLoan.pending]: (state) => {
      state.isLoading = true;
      state.isError = true;
    },
    [requestLoan.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.allData = payload.data;
      state.isError = false;
    },
    [requestLoan.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message || "An error occurred";
    },
    [editLoan.pending]: (state) => {
      state.isLoading = true;
      state.isError = true;
    },
    [editLoan.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.allData = payload.data;
      state.isError = false;
    },
    [editLoan.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message || "An error occurred";
    },
  },
});

export const { updateField, resetState } = loanDataSlice.actions;
export default loanDataSlice.reducer;
