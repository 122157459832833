import React from 'react'
import {
    Box, Flex
} from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'

function LoadingScreen() {
    return (
        <div className=''>
            <Box w='100%' color='white'>
                <Flex flexDirection={'column'} justifyItems={'center'} justify={'center'} justifyContent={'center'} alignItems={'center'} marginTop={'20'}>
                    <Spinner
                        thickness='9px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#5B2E4F'
                        size='xl'
                    />
                </Flex>
            </Box>
        </div>
    )
}

export default LoadingScreen