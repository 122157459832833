import React, { useState, } from "react";
import {
    Box, Container, Flex, Text, Button, Image, Circle, SimpleGrid, useBreakpointValue,

    Tab,
    Tabs,
    TabPanel,
    TabPanels,
    TabList,
    
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo from '../assets/HerVest Logo.svg'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

import { Img, } from "@chakra-ui/react";

import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

import lowbar from "../assets/landingimg/lowbar.png";
import phonefooter from "../assets/landingimg/phonefoote.webp";
import womanphone from '../assets/landingimg/womanphone.png'
import phonehappy from "../assets/landingimg/phonehappy.png";
import easy from "../assets/landingimg/easy.svg";
import singledigit from "../assets/landingimg/singledigit.svg";
import support from "../assets/landingimg/support.svg";
import writedown from "../assets/landingimg/writedown.png";
import mobileabout from "../assets/landingimg/mobileabout.png";

import wallet from "../assets/landingimg/wallet.svg";
import Profile from "../assets/landingimg/Profile.svg";
import flexible from "../assets/landingimg/flexible.svg";

import bgbg from "../assets/landingimg/bgbgbg.webp";
import whatloans from "../assets/landingimg/WhatLoans.webp";
import whatisloans from "../assets/landingimg/whatisloan.webp";
import herosection from "../assets/landingimg/hero.webp";

const HideBrokenImage = ({ src, alt, useMap, imgRef }) => {

    const [isBroken, setIsBroken] = useState(false);
    return (
        <Image
            src={src}
            alt={alt}
            ref={imgRef}
            useMap={useMap}
            onError={() => setIsBroken(true)} // Hide on error
            display={isBroken ? "none" : "block"}
            objectFit="cover"
        />
    );
};

const WidthBrokenImage = ({ src, alt, boxSize }) => {

    const [isWidthBroken, setWidthBroken] = useState(false);
    return (
        <Image
            src={src}
            alt={alt}
            boxSize={boxSize}
            objectFit="contain"
            onError={() => setWidthBroken(true)} // Hide on error
            display={isWidthBroken ? "none" : "block"}

        />
    );
};




const NewLandingPage = () => {

    const navigate = useNavigate();


    const textAlign = useBreakpointValue({ base: "center", md: "left" });
    const APKUrl = "https://play.google.com/store/apps/details?id=com.hervest";
    const IOSUrl = "https://apps.apple.com/ng/app/hervest-save-invest-loans/id1509714516";



    const midBoxWidth = useBreakpointValue({ base: '90%', md: '55%' });
    const boxWidth = useBreakpointValue({ base: "99%", md: "40%" });
    const marginX = useBreakpointValue({ base: 0, md: "40px" });

    const paddingXTab = useBreakpointValue({ base: 4, md: 200 });
    const fontSizeTab = useBreakpointValue({ base: "12px", md: "15px" });

    // const titleFontSize = useBreakpointValue({ base: "30px", md: "40px" });
    const subtitleFontSize = useBreakpointValue({ base: "15", md: "20px" });
    const widthApply = useBreakpointValue({ base: "90%", md: "30%" });

    const marginB = useBreakpointValue({ base: 5, md: 0 });
    const marginT = useBreakpointValue({ base: 5, md: 3 });

    const playButtonWidth = useBreakpointValue({ base: 150, md: 200 });
    const smallplayButtonWidth = useBreakpointValue({ base: 150, md: 180 });


    const herosrc = useBreakpointValue({
        base: null,
        md: herosection,
        lg: herosection,
    });

    const aboutloan = useBreakpointValue({
        base: whatisloans,
        md: whatloans,
        lg: whatloans,
    });

    const aboutloan2 = useBreakpointValue({
        base: mobileabout,
        md: null,
        lg: null,
    });

    const marginTop = useBreakpointValue({
        base: 20,
        md: 0,
        lg: 0,
    });

    return (
        <Box backgroundColor={"#FFFAFD"}>
            <Box
                top="5"
                left="150"
                backgroundColor={"#FFFAFD"}

                position={useBreakpointValue({ base: "initial", lg: "absolute", md: "absolute" })}

                width={useBreakpointValue({ base: "100%", lg: "45%", md: "45%" })}
                zIndex={1}
                marginTop={5}
                paddingLeft={useBreakpointValue({ base: "5%", lg: "0", md: "0" })}
            >
                <Img src={logo} objectFit="contain" w={["30%", "20%"]} />

            </Box>

            <Flex
                backgroundColor={"#FFFAFD"}
                paddingY={useBreakpointValue({ base: 5, lg: 20, md: 20 })}
                justify='space-around'
                align={textAlign}
                direction='row'

            >

                <Box className="flex flex-col"
                    align={textAlign}
                    position={useBreakpointValue({ base: "initial", lg: "absolute", md: "absolute" })}
                    top={useBreakpointValue({ base: "240", lg: "220", md: "240" })}
                    left={useBreakpointValue({ base: "150", lg: "150", md: "30" })}
                    width={useBreakpointValue({ base: "100%", lg: "50%", md: "45%" })}
                    zIndex={1}
                    paddingLeft={useBreakpointValue({ base: "12%", lg: "0", md: "0" })}
                >

                    <Text
                        textAlign={textAlign}
                        color="#B60082"
                        fontWeight="500"
                        fontSize="32px"
                        fontFamily='heading'
                        lineHeight={1}
                        marginBottom={'5px'}
                    >
                        Get a
                    </Text>
                    <Text
                        textAlign={textAlign}
                        color="#B60082"
                        fontWeight="500"
                        fontSize={useBreakpointValue({ base: "100", lg: "150", md: "120" })}
                        lineHeight={1}
                        fontFamily="fraunces"
                        marginBottom={'3px'}
                    >
                        LOAN
                    </Text>

                    <Text
                        textAlign={textAlign}
                        color="#B60082"
                        fontWeight="400"
                        fontSize="32px"
                        fontFamily='heading'
                        lineHeight={1}
                        marginBottom={'10px'}
                    >
                        in minutes
                    </Text>

                    <Text
                        textAlign={textAlign}
                        marginTop={useBreakpointValue({ base: 0, lg: 25, md: 25 })}

                        color="#540A45"
                        fontWeight="400"
                        fontSize="22px"
                        fontFamily='heading'
                        lineHeight={1}
                        marginBottom={'10px'}
                    >
                        No Paperwork, Competitive Rates, Flexible
                    </Text>

                    <Text
                        textAlign={textAlign}
                        color="#540A45"
                        fontWeight="400"
                        fontSize="22px"
                        fontFamily='heading'
                        lineHeight={1}
                        marginBottom={'10px'}
                    >
                        Repayment Terms
                    </Text>


                    <Button
                        bg="#540445"
                        borderRadius="6px"
                        fontSize="15px"
                        my={"25px"}
                        fontWeight="500"
                        color="white"
                        textAlign={'center'}
                        px={"35px"}
                        py={"25px"}
                        // p="20px 35px"
                        _hover={{
                            color: "#540445",
                            border: "1px solid #540445",
                            bg: "white",
                        }}
                        onClick={() => {
                            navigate("/home");
                        }}
                    >
                        Apply for a loan
                    </Button>


                    <Flex direction="row" marginTop={marginT} marginBottom={marginB}>
                        <GooglePlayButton width={smallplayButtonWidth} url={APKUrl} theme="dark" className="smallplaystore" />
                        <Text marginRight={5}></Text>
                        <AppStoreButton width={smallplayButtonWidth} url={IOSUrl} theme="dark" className="smallplaystore" />
                    </Flex>

                </Box>

                <Image src={herosrc} w={"60%"} ml={"30%"} objectFit="cover" />

            </Flex>


            <HideBrokenImage src={aboutloan} />
            <HideBrokenImage src={aboutloan2} />


            <Box
                bg="pink.50"
                backgroundImage={bgbg}
                bgPosition="right bottom"
                bgRepeat="no-repeat"
                bgSize="cover"
                py={16}
            >


                <Container maxW="container.xl">

                    <Text fontSize="3xl" marginBottom={20} fontWeight="900" textAlign="center" color="#5B2E4F">
                        Who Can Apply for HerVest Loans?
                    </Text>

                    <Flex justify='center' wrap={"wrap"} >


                        <Box width={widthApply} bg="#FDEDED" p={8} borderRadius="lg" shadow="md">

                            <Box>

                                <Image src={Profile} w={10} h={10} />
                                <Text fontSize="xl" fontWeight="bold" color="pink.700">Salary Earner</Text>
                                <Text fontSize="md" mt={2}>
                                    We want you to maintain focus at work while meeting your financial needs with the support of HerVest loans.

                                </Text>
                            </Box>

                            <Button
                                onClick={() => {
                                    navigate("/home");
                                }}
                                bg="#FDEDED"
                                borderRadius="6px"
                                fontSize="12px"
                                fontWeight="500"
                                marginTop={16}
                                border="1px solid #5B2E4F"
                                p={["8px 32px", "13px 52px"]}
                                _hover={{ color: "white", border: "1px solid #5B2E4F", bg: "#5B2E4F" }}
                            // textTransform="uppercase"
                            >
                                Apply Now
                            </Button>

                        </Box>



                        <Box ml={useBreakpointValue({ base: 0, md: 7, lg: 7 })} mt={useBreakpointValue({ base: 7, md: 0, lg: 0 })} width={widthApply} bg="#FFEEF7" p={8} borderRadius="lg" shadow="md">

                            <Box >
                                <Image src={wallet} w={10} h={10} />
                                <Text fontSize="xl" fontWeight="bold" color="pink.700">Business Owner</Text>
                                <Text fontSize="md" mt={2}>
                                    We understand that money is vital to achieving your business goals. HerVest offers access to low-interest loans  that allows expand your business.
                                </Text>

                            </Box>

                            <Button
                                onClick={() => {
                                    navigate("/home");
                                }}
                                bg="#FDEDED"
                                borderRadius="6px"
                                fontSize="12px"
                                fontWeight="500"
                                marginTop={10}
                                border="1px solid #5B2E4F"
                                p={["8px 32px", "13px 52px"]}
                                _hover={{ color: "white", border: "1px solid #5B2E4F", bg: "#5B2E4F" }}
                            // textTransform="uppercase"
                            >
                                Apply Now
                            </Button>
                        </Box>

                    </Flex>


                </Container>

            </Box>



            <Flex

                backgroundColor={"#FFFAFD"}
                paddingTop={24}
                paddingBottom={24}
                justify='space-around'
                align={textAlign}
                direction='row'
                flexWrap="Wrap"
            >

                <Box className="flex flex-col" align={textAlign}>

                    <Text
                        textAlign={textAlign}
                        marginTop={useBreakpointValue({ base: 0, lg: 65, md: 65 })}
                        color="#540A45"
                        fontWeight="600"
                        fontSize="32px"
                        fontFamily='heading'
                        lineHeight={1}
                        marginBottom={'10px'}
                    >
                        Why People
                    </Text>

                    <Text
                        textAlign={textAlign}
                        color="#540A45"
                        fontWeight="600"
                        fontSize="32px"
                        fontFamily='heading'
                        lineHeight={1}
                        marginBottom={'20px'}
                    >
                        Choose Us?
                    </Text>


                    <Text textAlign={textAlign} >
                        People prefer HerVest loans for
                    </Text>
                    <Text textAlign={textAlign} >
                        many reasons
                    </Text>

                    <Button
                        bg="#540445"
                        borderRadius="6px"
                        fontSize="12px"
                        my={"25px"}
                        fontWeight="500"
                        color="white"
                        textAlign={'center'}
                        p="13px 32px"
                        _hover={{
                            color: "#540445",
                            border: "1px solid #540445",
                            bg: "white",
                        }}
                        onClick={() => {
                            navigate("/home");
                        }}
                        textTransform="uppercase"
                    >
                        APPLY FOR A LOAN
                    </Button>

                </Box>


                <Box width={midBoxWidth} className="flex flex-col">


                    <Flex marginBottom={useBreakpointValue({ base: 0, md: 30 })} wrap={"wrap"} >

                        <Box
                            // border="1px solid black"
                            width={boxWidth}
                            mx={marginX}
                            boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                            bg="whiteAlpha.800"
                            borderRadius="15px"
                            px="30px"
                            pt="20px"
                            pb="10px"
                            mb={marginTop}
                        >
                            <Img width={10} height={10} src={easy} />
                            <Text
                                color="#540A45"
                                fontWeight="700"
                                fontSize="25px"
                                fontFamily="heading"
                                my="5px"
                            >
                                Easy Application Process
                            </Text>

                            <Text my="5px">
                                Our Process is simple quick, and paperless
                            </Text>

                        </Box>

                        <Box
                            // border="1px solid black"
                            width={boxWidth}
                            boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                            bg="whiteAlpha.800"
                            borderRadius="15px"
                            px="30px"
                            pt="20px"
                            pb="10px"
                            mb={marginTop}
                        >
                            <Img width={10} height={10} src={singledigit} />
                            <Text
                                color="#540A45"
                                fontWeight="700"
                                fontSize="25px"
                                fontFamily="heading"
                                my="5px"
                            >
                                Single-digit Interest Rates
                            </Text>
                            <Text my="5px">
                                Our interest rate is capped at 4% and of course, the best rate you can find in the market.
                            </Text>

                        </Box>

                    </Flex>


                    <Flex wrap={"wrap"}>

                        <Box
                            // border="1px solid black"
                            width={boxWidth}
                            mx={marginX}
                            boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                            bg="whiteAlpha.800"
                            borderRadius="15px"
                            px="30px"
                            pt="20px"
                            pb="10px"
                            mb={marginTop}
                        >
                            <Img width={10} height={10} src={flexible} />
                            <Text
                                color="#540A45"
                                fontWeight="700"
                                fontSize="25px"
                                fontFamily="heading"
                                my="5px"
                            >
                                Flexible Repayment Terms
                            </Text>
                            <Text my="5px">
                                Whether you prefer monthly, quarterly, or annual installments, we have options to accommodate your cash flow.
                            </Text>

                        </Box>

                        <Box
                            // border="1px solid black"
                            width={boxWidth}
                            boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                            bg="whiteAlpha.800"
                            borderRadius="15px"
                            px="30px"
                            pt="20px"
                            pb="10px"
                            mb={marginTop}
                        >
                            <Img width={10} height={10} src={support} />
                            <Text
                                color="#540A45"
                                fontWeight="700"
                                fontSize="25px"
                                fontFamily="heading"
                                my="5px"
                            >
                                Support
                            </Text>
                            <Text my="5px">
                                You get access to 24/7 tailored support to help you make a successful application.
                            </Text>

                        </Box>

                    </Flex>


                </Box>


            </Flex>



            <Box bg="white" pb={16} pt={7} align="center" >

                <Text fontSize="4xl" mb={4} fontWeight="bold" textAlign="center" color={"#540A45"}>
                    Requirements
                </Text>


                <Box bg="#FFF2E7" width={useBreakpointValue({ base: "90%", md: "55%", lg: "50%" })} p={8} mb={10} borderRadius="lg" shadow="md">

                    <Flex align="center">

                        <WidthBrokenImage
                            boxSize={useBreakpointValue({ base: 0, md: 350, lg: 350 })}
                            src={useBreakpointValue({ base: null, md: phonehappy, lg: phonehappy })} />


                        <Box ml={4}>
                            <Text fontSize="2xl" color={"#540A45"} textAlign={'start'} fontWeight="bold">
                                Personal Loan
                            </Text>
                            {["Must reside in Lagos", "BVN", "Utility Bill", "Government ID", "6 Months Bank Statement"].map((item, index) => (
                                <Flex alignItems="center" justify="left" key={index}>
                                    <Box width={2} height={2} mr={4} bg="#FA2A55" borderRadius="full" />
                                    <Text textAlign={'start'} my="5px">{item}</Text>
                                </Flex>
                            ))}
                        </Box>

                    </Flex>

                </Box>

                <Box bg="#FFEEF7" width={useBreakpointValue({ base: "90%", md: "55%", lg: "50%" })} p={8} mb={10} borderRadius="lg" shadow="md">

                    <Flex align="center">



                        <Box ml={4}>
                            <Text fontSize="2xl" color={"#540A45"} textAlign={'start'} fontWeight="bold">
                                Business Loan
                            </Text>
                            {["Must reside in Lagos", "BVN", "Utility Bill", "Government ID", "6 Months Bank Statement", "CAC document", "Passport Photograph"].map((item, index) => (
                                <Flex alignItems="center" justify="left" key={index}>
                                    <Box width={2} height={2} mr={4} bg="#FA2A55" borderRadius="full" />
                                    <Text textAlign={'start'} my="5px">{item}</Text>
                                </Flex>
                            ))}
                        </Box>

                        <WidthBrokenImage
                            boxSize={useBreakpointValue({ base: 0, md: 350, lg: 350 })}
                            src={useBreakpointValue({ base: null, md: writedown, lg: writedown })} />

                    </Flex>

                </Box>



            </Box>

            <Container maxW="container.xl">

                <Text fontSize="4xl" pt={7} fontWeight="bold" textAlign="center" color={"#540A45"}>
                    Start Your Loan Journey
                </Text>


                <Flex justify='space-evenly' alignItems={'flex-end'} mt={8}>

                    <WidthBrokenImage
                        boxSize={useBreakpointValue({ base: 0, md: 400, lg: 400 })}
                        src={useBreakpointValue({ base: null, md: womanphone, lg: womanphone })} />


                    <SimpleGrid rows={{ base: 1, md: 4 }} spacing={8} mt={8}>

                        <Flex align="center">
                            <Circle size={useBreakpointValue({ base: 30, md: 50, lg: 50 })} border="2px solid #5B2E4F" color="#540A45">
                                1
                            </Circle>
                            <Text fontWeight="bold" ml={useBreakpointValue({ base: 4, md: 10, lg: 10 })} color="#540A45" fontSize="lg">
                                Submit Personal Information
                            </Text>
                        </Flex>


                        <Flex align={useBreakpointValue({ base: "left", md: "center", lg: "center" })}>
                            <Circle size={useBreakpointValue({ base: 30, md: 50, lg: 50 })} border="2px solid #5B2E4F" color="#540A45">
                                2
                            </Circle>
                            <Text fontWeight="bold" ml={useBreakpointValue({ base: 4, md: 10, lg: 10 })} color="#540A45" fontSize="lg">
                                Upload Vital Document
                            </Text>
                        </Flex>


                        <Flex align="center">
                            <Circle size={useBreakpointValue({ base: 30, md: 50, lg: 50 })} border="2px solid #5B2E4F" color="#540A45">
                                3
                            </Circle>
                            <Text fontWeight="bold" ml={useBreakpointValue({ base: 4, md: 10, lg: 10 })} color="#540A45" fontSize="lg">
                                Wait for Loan Review
                            </Text>
                        </Flex>

                        <Flex align="center" mb={10}>
                            <Circle size={useBreakpointValue({ base: 30, md: 50, lg: 50 })} border="2px solid #5B2E4F" color="#540A45">
                                4
                            </Circle>
                            <Text fontWeight="bold" ml={useBreakpointValue({ base: 4, md: 10, lg: 10 })} color="#540A45" fontSize="lg">
                                Accept your Loan Offer
                            </Text>
                        </Flex>

                    </SimpleGrid>

                </Flex>


            </Container>





            <Box backgroundColor={"#FFF6FB"} pt={1}>

                <Text marginTop="65px" fontSize="4xl" mb={4} fontWeight="bold" textAlign="center" color={"#540A45"}>
                    Frequently Asked Questions?
                </Text>



                <Text
                    fontWeight="400"
                    fontSize={subtitleFontSize}
                    textAlign="center"
                    fontFamily="heading"
                    lineHeight={1}
                    marginBottom={2}
                >
                    There's a really high chance we already answered the question you have below.
                </Text>
                <Text
                    fontWeight="400"
                    fontSize={subtitleFontSize}
                    textAlign="center"
                    fontFamily="heading"
                    lineHeight={1}
                    marginBottom={12}
                >
                    Choose a category to see.
                </Text>


                <Tabs
                    // backgroundImage={loanwatermark}
                    // backgroundSize={'contain'}

                    isFitted
                    paddingX={paddingXTab} >
                    <TabList borderRadius={15} backgroundColor={"white"} paddingLeft={4} paddingY={3} overflowX={'visible'} overflowY={'hidden'} className="faqs" >
                        {["GENERAL", "DISBURSEMENT", "GUARANTOR", "COLLATERAL", "REPAYMENT"].map((tab, index) => (
                            <Tab borderRadius={15} key={index} color="grey" fontSize={fontSizeTab} fontWeight="semibold"
                                _selected={{ backgroundColor: "pink.300", color: "pink.900" }}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </TabList>

                    <TabPanels >

                        <TabPanel>
                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    What is HerVest Loan?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        HerVest Loan is a tailored financing solution designed to assist individuals and women-owned businesses in achieving their financial objectives quickly and easily. It provides easy access to finance that may otherwise be challenging to obtain from traditional financial institutions.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    Is HerVest Loan for women only?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        HerVest Loan is not exclusively for women. It is a financial solution for everyone who meets the loan requirements.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left'>
                                            <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                What are the benefits of using HerVest Loan?
                                            </Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>

                                    <AccordionPanel pb={4}>

                                        <Text textAlign="left">
                                            The benefits of HerVest Loan include:
                                            <ul>
                                                <li>Fast and easy application process</li>
                                                <li>Minimal stress with limited documentation required</li>
                                                <li>Flexible repayment terms to suit individual needs</li>
                                            </ul>
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    What types of HerVest Loans are available?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>

                                        <Text textAlign="left">
                                            HerVest offers two types of loans: Personal Loans and Business Loans:
                                            <ul>
                                                <li>Personal loans are for salary earners seeking financing to meet personal needs and goals.</li>
                                                <li>Business loans provide working capital to SMEs to support financial and business objectives.</li>
                                            </ul>
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    What documents are required to access a personal loan?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>

                                        <Text textAlign="left">
                                            For Personal Loans:
                                            <ul>
                                                <li>BVN, employment and Next of Kin details</li>
                                                <li>NIN number</li>
                                                <li>Utility bill</li>
                                                <li>Passport photograph</li>
                                                <li>Government ID (NIN slip, International passport, Driver’s license, or Voter’s card)</li>
                                                <li>Bank statement of 6 months</li>
                                            </ul>
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    What documents are required to access a business loan?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>

                                        <Text textAlign="left">
                                            For Business Loans:
                                            <ul>
                                                <li>BVN, relevant business details, and a Guarantor</li>
                                                <li>NIN number</li>
                                            </ul>
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>


                            </Accordion>

                        </TabPanel>





                        <TabPanel >

                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    How will my Loan be Disbursed ?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            After accepting your loan offer, we will generate a slip and send it to your email. Then, you will print out the slip, sign and submit to your bank.
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    How do I activate Remita on my Account ?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            After accepting your offer, we generate a slip and send it to your email, you will print out the slip, sign and submit to your bank
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>





                        </TabPanel>

                        <TabPanel >

                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    Who needs a Guarantor ?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            Business loans above ₦500,000 Naira will need a Guarantor
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    Who can be my guarantor ?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            Your guarantor is anyone who can vouch for you.
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    What details do I need from my Guarantor ?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            Your guarantor will need to provide a 6-month bank statement, passport photograph, means of identification and a signed blank cheque.
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                            </Accordion>

                        </TabPanel>




                        <TabPanel >

                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    Is Collateral Needed?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            HerVest require Collateral for loan amounts of 5 million Naira and above.
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>


                            </Accordion>


                        </TabPanel>

                        <TabPanel  >

                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    How will I repay my loans ?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            Ensure your remita activated account is funded with the repayment amount.
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    Can I liquidate my loans before maturity ?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            Yes. Contact the HerVest loan team at care@hervest.ng
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                                                    What if I default on my Repayment ?
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="left">
                                            4% of Principle amount will be charged as default charge.
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                            </Accordion>

                        </TabPanel>

                    </TabPanels>

                </Tabs>
            </Box>


            <Box backgroundColor={"#FFFFFF"} px={useBreakpointValue({ base: 5, md: 150, lg: 200 })} py={30}>
                <HideBrokenImage src={phonefooter} />
            </Box>


            <Flex backgroundColor={"#FFFFFF"} justify={'space-around'} wrap={'wrap'} ml={useBreakpointValue({ base: 0 })}>
                <Box>
                    <Text color={"#540A45"} fontWeight={'500'} >+234-902-347-5608 (WhatsApp Only)</Text>
                    <Text color={"#540A45"} fontWeight={'500'} >hello@hervest.ng</Text>
                    <Text color={"#540A45"} fontWeight={'500'} >www.hervest.ng</Text>
                </Box>


                <Flex backgroundColor={"#FFFFFF"} direction="row" marginTop={marginT} marginBottom={marginB}>
                    <GooglePlayButton width={playButtonWidth} url={APKUrl} theme="dark" className="playstore" />
                    <Text marginRight={5}></Text>
                    <AppStoreButton width={playButtonWidth} url={IOSUrl} theme="dark" className="playstore" />
                </Flex>


            </Flex>


            <HideBrokenImage src={lowbar} />
        </Box>
    );
};

export default NewLandingPage;
