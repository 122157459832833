import { Box, Flex, Text, Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Navbar from "../components/Others/Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetState } from "../features/loanSlice";
import Success from "../assets/lottie/Success.json";
import { Player } from "@lottiefiles/react-lottie-player";

const SuccessPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetState());
    localStorage.clear();
  }, [dispatch]);

  //Get The Id from url search Param

  const urlParams = new URLSearchParams(window.location.search);
  const requestId = urlParams.get("id");

  return (
    <Box p={["20px 10px", "20px 30px"]}>
      <Navbar />

      <Flex justify="center" mt="50px" align="center">
        <Box
          w={["100%", "80%", "60%"]}
          boxShadow="0px 4px 12px 0px rgba(197, 197, 197, 0.25)"
          borderRadius="35px"
          border="0.5px solid #EFEAED"
          p={["20px 30px", "25px 35px", "45px 55px 45px 55px"]}
        >
          <Text
            p="10px 10px 20px 10px"
            fontSize={["18px", "22px"]}
            fontWeight={["500", "700"]}
            color="#540A45"
            fontFamily="SatoshiBold"
          >
            Success!
          </Text>

          <Box
            w="100%"
            h="300px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Player
              style={{ width: "80%", height: "100%" }}
              src={Success}
              loop
              autoplay
            />
          </Box>

          <Text>
            <Text
              fontSize={["16px", "18px"]}
              fontWeight={["400", "500"]}
              color="#5B2E4F"
              mb={["30px", "50px"]}
            >
              Your Loan application has been submitted successfully and will be
              reviewed. Please note a decision will be sent within
              <span
                style={{
                  color: "#5B2E4F",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                {" "}
                24 - 48
              </span>{" "}
              working hours{" "}
            </Text>
          </Text>

          <Flex justify="flex-end" mt="10px">
            <Button
              onClick={() => {
                dispatch(resetState());
                navigate("/");
              }}
              bg="white"
              borderRadius="6px"
              fontSize="12px"
              fontWeight="500"
              color="#5B2E4F"
              border="1px solid #5B2E4F"
              p={["8px 32px", "13px 52px"]}
              _hover={{
                color: "white",
                border: "1px solid #5B2E4F",
                bg: "#5B2E4F",
              }}
              textTransform="uppercase"
            >
              Go Back
            </Button>
            <Button
              onClick={() => {
                dispatch(resetState());
                navigate(`/review/${requestId}`);
              }}
              ml="5px"
              type="submit"
              bg="#5B2E4F"
              borderRadius="6px"
              fontSize="12px"
              fontWeight="500"
              color="white"
              p="13px 52px"
              _hover={{
                color: "#5B2E4F",
                border: "1px solid #5B2E4F",
                bg: "white",
              }}
            >
              TRACK APPLICATION
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default SuccessPage;
