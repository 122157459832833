import { Box, Flex, HStack, Text, Divider, Circle } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../components/Others/Navbar";
import FirstForm from "../components/Forms/FirstForm";
import SecondForm from "../components/Forms/SecondForm";
import NextofKin from "../components/Forms/NextofKinForm";
import ConvenantForm from "../components/Forms/ConvenantForm";
import FinalOfferForm from "../components/Forms/FinalOfferForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PreviewDetails from "../components/Forms/PreviewDetails";
// import { FaArrowUp, FaArrowDown } from "react-icons/fa";

const Home = () => {
  const allLoanData = useSelector((state) => state.loanData);
  const [currentScreen, setCurrentScreen] = useState(1);
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!allLoanData?.type) {
      localStorage.removeItem("wrongnin");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLoanData]);

  return (
    <Box p={["20px 10px", "20px 30px"]}>
      <Navbar />

      <Box mt="20px">
        <Text
          color="#540A45"
          fontSize={["14px", "21px"]}
          fontWeight="700"
          fontFamily="SatoshiBolder"
          lineHeight="28.35px"
          textAlign={["left", "center"]}
        >
          LOAN APPLICATION FORM FOR
          {allLoanData?.type === "personal" ? " SALARY EARNERS" : " MSMEs"}
        </Text>
        <HStack justify="center" m="25px 0">
          <HStack>
            <Flex
              bg="#540A45"
              borderRadius="50%"
              w="28px"
              h="26px"
              justify="center"
              align="center"
            >
              <Text
                color="#FFFFFF"
                fontSize="13px"
                fontWeight="700"
                cursor="pointer"
              >
                1
              </Text>
            </Flex>
            <Divider
              color={currentScreen >= 1 ? "#540A45" : "#666666"}
              height="2px"
              width="35px"
              borderBottom="2px dotted"
            />
          </HStack>
          <HStack justify="end">
            <Circle
              size="28px"
              bg={currentScreen >= 2 ? "#540A45" : "#666666"}
              cursor="pointer"
              children="2"
              color="white"
              fontSize="13px"
              opacity={currentScreen >= 2 ? 1 : "44%"}
            />
            <Divider
              color={currentScreen >= 2 ? "#540A45" : "#666666"}
              height="2px"
              width="35px"
              borderBottom="2px dotted"
            />
          </HStack>
          <HStack justify="end">
            <Circle
              size="28px"
              bg={currentScreen >= 3 ? "#540A45" : "#666666"}
              cursor="pointer"
              children="3"
              color="white"
              fontSize="13px"
              opacity={currentScreen >= 3 ? 1 : "44%"}
            />
            <Divider
              color={currentScreen >= 3 ? "#540A45" : "#666666"}
              height="2px"
              width="35px"
              borderBottom="2px dotted"
            />
          </HStack>
          <HStack justify="end">
            <Circle
              size="28px"
              bg={currentScreen >= 4 ? "#540A45" : "#666666"}
              cursor="pointer"
              children="4"
              color="white"
              fontSize="13px"
              opacity={currentScreen >= 4 ? 1 : "44%"}
            />
          </HStack>
        </HStack>
      </Box>
      {currentScreen === 1 ? (
        <FirstForm setCurrentScreen={setCurrentScreen} />
      ) : currentScreen === 2 ? (
        <SecondForm setCurrentScreen={setCurrentScreen} />
      ) : currentScreen === 3 ? (
        <NextofKin setCurrentScreen={setCurrentScreen} />
      ) : currentScreen === 4 ? (
        <ConvenantForm setCurrentScreen={setCurrentScreen} />
      ) : currentScreen === 5 ? (
        <PreviewDetails setCurrentScreen={setCurrentScreen} />
      ) : (
        <FinalOfferForm setCurrentScreen={setCurrentScreen} />
      )}
    </Box>
  );
};

export default Home;
