import React from 'react'
import {
    Box,  Table,
    Thead,
    Tbody,
    Tr,
    Td,
    TableContainer,
} from '@chakra-ui/react'

const FinalformTable = () => {
  return (
      <Box m="0 auto" w={["100%", "80%", "60%"]}>
          <TableContainer border="1px solid #EFEAED">
              <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Td fontSize="14px" color="#33343D" fontWeight="700" fontFamily="SatoshiBold" lineHeight="19px">Loan Summary</Td>

                        </Tr>
                    </Thead>
                  <Tbody>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                          <Td borderRight="1px solid #CBD5E0">Amount (Principal)</Td>
                            <Td> ₦1150,000</Td>
                        </Tr>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                           <Td borderRight="1px solid #EFEAED">Tenor</Td>
                            <Td>6 Months</Td>
                        </Tr>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                          <Td borderRight="1px solid #EFEAED" >Interest Rate</Td>
                            <Td>3.5% Monthly</Td>
                        </Tr>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                          <Td borderRight="1px solid #EFEAED">Maturity Date</Td>
                            <Td>16th January, 2024</Td>
                        </Tr>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                          <Td borderRight="1px solid #EFEAED">Monthly Repayment</Td>
                            <Td>₦30,161.05</Td>
                        </Tr>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                          <Td borderRight="1px solid #EFEAED">Weekly Repayment</Td>
                            <Td>₦7,600</Td>
                        </Tr>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                          <Td borderRight="1px solid #EFEAED">Repayment Date</Td>
                            <Td>16th of every month</Td>
                        </Tr>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                          <Td borderRight="1px solid #EFEAED">Processing Fee (1%)</Td>
                            <Td>₦1,500</Td>
                        </Tr>
                        <Tr fontSize="13px" color="#33343D" fontWeight="500" fontFamily="SatoshiBold" lineHeight="19px">
                          <Td borderRight="1px solid #EFEAED">Insurance Fee (2.5%)</Td>
                            <Td>₦13,750</Td>
                        </Tr>
                  </Tbody>
              </Table>
          </TableContainer>
    </Box>
  )
}

export default FinalformTable
