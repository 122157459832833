import { configureStore } from '@reduxjs/toolkit';
import loanDataSlice from './features/loanSlice';
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { persistStore } from 'redux-persist';
import storage from "redux-persist/es/storage";

const config = {
    key: 'hervest-loan-reducers',
    storage
}
const rootReducer = combineReducers({
    loanData: loanDataSlice
});

export const persistedRootReducer = persistReducer(config, rootReducer);

export const store = configureStore({
    reducer: persistedRootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
});

//Implementing Redux Persist to persist state 

export const persiststore = persistStore(store);
