export const knownYears = [
  { value: "0 - 6Months", label: "0 - 6 Months" },
  { value: "6Months - 1Year", label: "6 Months - 1Year" },
  { value: "1 - 5Years", label: "1 - 5 Years" },
  { value: "5 - 10Years", label: "5 - 10 Years" },
  { value: "10Years +", label: "10 Years +" },
];

export const nigeriaStates = [
  "Lagos",
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
