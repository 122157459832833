import React from "react";
import { motion } from "framer-motion";
// import hervestlogo from "../../assets/images/hervest_splash.svg";
import hervestlogo from "../../assets/HerVest Logo.svg";

function SplashScreen() {
  return (
    <div className="onboarding">
      <div className="p-8 grid justify-items-center gap-y-8 ">
        <motion.div
          className="splash-logo"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <a href="https://loan.hervest.ng">
            <img src={hervestlogo} alt="HerVest Logo"></img>
          </a>
        </motion.div>
      </div>
    </div>
  );
}

export default SplashScreen;
