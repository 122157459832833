import React from "react";
import {
  Button,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Divider,
  Flex,
  HStack,
  RadioGroup,
  Radio,
  Img,
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorMsg from "../Errors/ErrorMessage";
import { useNavigate } from "react-router-dom";
import personalIcon from "../../assets/personal.svg";
import businessIcon from "../../assets/business.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateField } from "../../features/loanSlice";

function SelectLoanApplicationType({ isOpen, setIsOpen }) {
  const navigate = useNavigate();
  const allLoanData = useSelector((state) => state.loanData);
  const dispatch = useDispatch();
  const proceedHandler = (value) => {
    dispatch(
      updateField({
        ...allLoanData,
        type: value.loanType,
      })
    );
    navigate(`/request/${value.loanType}`);
  };

  return (
    <>
      <Modal
        isCentered
        onClose={() => setIsOpen(isOpen)}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size="2xl"
      >
        <ModalOverlay />
        <Formik
          initialValues={{
            loanType: "",
          }}
          validationSchema={Yup.object({
            loanType: Yup.string().required(
              "Loan Application type is required"
            ),
          })}
          onSubmit={(values) => {
            console.log(values, "the values heheeh");
            proceedHandler(values);
          }}
        >
          {({ values, setValues, handleSubmit }) => (
            <ModalContent>
              <Flex justify="center" mt="50px" align="center">
                <Box
                  w={["100%"]}
                  boxShadow="0px 4px 12px 0px rgba(197, 197, 197, 0.25)"
                  borderRadius="35px"
                  border="0.5px solid #EFEAED"
                  p={["20px 30px", "25px 35px", "45px 55px 45px 55px"]}
                >
                  <Text
                    p="10px 10px 20px 10px"
                    fontSize={["18px", "22px"]}
                    fontWeight={["500", "700"]}
                    color="#540A45"
                    fontFamily="SatoshiBold"
                  >
                    Choose your Loan Application Type
                  </Text>
                  <RadioGroup
                    defaultValue=""
                    onChange={(value) => {
                      console.log(value, "the value");
                      setValues({ ...values, loanType: value });
                    }}
                    value={values.loanType}
                  >
                    <HStack mt="30px" spacing={[2, 3, 5]}>
                      <Box
                        onClick={() =>
                          setValues({
                            ...values,
                            loanType: "personal",
                          })
                        }
                        border={
                          values.loanType === "personal"
                            ? "1px solid #5B2E4F"
                            : "1px solid #EFEAED"
                        }
                        borderRadius="16px"
                        p="20px 25px 20px 20px"
                      >
                        <HStack justify="space-between">
                          <Img src={personalIcon} objectFit="contain" />
                          <Radio value="personal" color="#5B2E4F"></Radio>
                        </HStack>
                        <Text
                          fontSize={["14px", "18px"]}
                          fontWeight={["500", "700"]}
                          color="#540A45"
                          fontFamily="SatoshiBold"
                          pt="10px"
                        >
                          Personal Loan
                        </Text>
                        <Text
                          fontSize={["14px", "16px"]}
                          fontWeight={["500"]}
                          color="#33343D"
                          fontFamily="SatoshiBold"
                        >
                          Access financial support as a salary earner
                        </Text>
                      </Box>
                      <Box
                        onClick={() =>
                          setValues({
                            ...values,
                            loanType: "business",
                          })
                        }
                        border={
                          values.loanType === "business"
                            ? "1px solid #5B2E4F"
                            : "1px solid #EFEAED"
                        }
                        borderRadius="16px"
                        p="20px 25px 20px 20px"
                      >
                        <HStack justify="space-between">
                          <Img src={businessIcon} objectFit="contain" />
                          <Radio value="business" color="#5B2E4F"></Radio>
                        </HStack>
                        <Text
                          fontSize={["14px", "18px"]}
                          fontWeight={["500", "700"]}
                          color="#540A45"
                          fontFamily="SatoshiBold"
                          pt="10px"
                        >
                          Business Loan
                        </Text>
                        <Text
                          fontSize={["14px", "16px"]}
                          fontWeight={["500"]}
                          color="#33343D"
                          fontFamily="SatoshiBold"
                        >
                          Secure capital to expand your business
                        </Text>
                      </Box>
                    </HStack>
                  </RadioGroup>

                  <ErrorMsg name="loanType" />

                  <Divider
                    mt="30px"
                    color="#666666"
                    opacity="85%"
                    height="2px"
                    width="100%"
                    borderBottom="2px color=1px solid #5B2E4F1F"
                  />
                  <Flex justify="flex-end" mt="10px">
                    <Button
                      ml="5px"
                      type="submit"
                      bg="#5B2E4F"
                      borderRadius="6px"
                      fontSize="12px"
                      fontWeight="500"
                      color="white"
                      p="13px 52px"
                      _hover={{
                        color: "#5B2E4F",
                        border: "1px solid #5B2E4F",
                        bg: "white",
                      }}
                      onClick={handleSubmit}
                    >
                      PROCEED
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </ModalContent>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default SelectLoanApplicationType;
