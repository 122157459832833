import { useEffect } from "react";
import { useFormikContext } from "formik";
import AWS from "aws-sdk";

/**
 *
 * @param {*} inputText
 * @returns A INPUT CLEANED AND REMOVED EVERY SPECIAL CHARACTERS
 * @description A FUNCTION USED TO CLEAN INPUTS AND REMOVE SPECIAL CHARACTERS
 */
export function cleanTextInputField(inputText) {
  if (!inputText) return;
  const cleanedText = inputText.replace(/[^a-zA-Z0-9]/g, "");
  return cleanedText;
}

/**
 * For Field s with Error, The User get automatically scrolled to the point of error
 * @param {Object} errors A Formik form errors
 * @returns {Array}
 * @description Using the dot nottation principle
 */

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

/**
 *
 * @returns  the scroll behaviour to the point of error
 */
export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();
  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;
    element.scrollIntoView(scrollBehavior);
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileBase64 = (reader.onload = () => resolve(reader.result));
    reader.onerror = (error) => reject(error);
    return fileBase64;
  });
}

export async function uploadFileToAWS(file) {
  console.log(process.env.REACT_APP_AWS_ACCESS_KEY, "the keyy");
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  });

  const hervestBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
    ACL: "public-read",
    Body: file,
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: file.name,
  };

  hervestBucket
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      console.log(evt, "THE EVTTTTT");
      // setProgress(Math.round((evt.loaded / evt.total) * 100));
    })
    .send((err) => {
      if (err) console.log(err);
    });
}
