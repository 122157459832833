import React from 'react'
import { ErrorMessage } from 'formik'
import { Text } from '@chakra-ui/react'

function ErrorMsg({ name }) {
    return (
        <Text color={'red.300'} fontSize={'14px'} fontFamily={'satoshi'} fontWeight={'extrabold'}>
            <ErrorMessage name={name} />
        </Text>
    )
}

export default ErrorMsg