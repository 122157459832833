import { Box } from "@chakra-ui/react";
import React from "react";
import { FileUploader } from "react-drag-drop-files";

function FileUploadField({ handleChange, name, types, children }) {
  return (
    <div>
      <FileUploader
        handleChange={handleChange}
        name={name}
        types={types}
        children={
          <>
            <Box w={["100%"]} h={["100%"]}>
              {children}
            </Box>
          </>
        }
      />
    </div>
  );
}

export default FileUploadField;
