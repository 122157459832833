/* eslint-disable no-useless-escape */
import {
  Box,
  Flex,
  Text,
  Divider,
  HStack,
  VStack,
  Img,
  Button,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import flag from "../../assets/flag.svg";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  requestLoan,
  updateField,
  resetState,
  editLoan,
} from "../../features/loanSlice";
import { ScrollToFieldError } from "../../services/utils/helpers";
import { nigeriaStates } from "../../services/utils/constants";
import IneligibleApplicant from "../Modal/ineligibleApplicant";

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

const FirstForm = ({ setCurrentScreen }) => {
  const request_id = localStorage.getItem("request_id");
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isIneligible, setIsIneligible] = useState(false);
  const allLoanData = useSelector((state) => state.loanData);

  const initialvalues = {
    firstName: allLoanData?.first_name || "",
    lastName: allLoanData?.last_name || "",
    email: allLoanData?.email || "",
    bvn: allLoanData?.bvn || "",
    maidenName: allLoanData?.alias || "",
    gender: allLoanData?.gender || "",
    maritalStatus: allLoanData?.marital_status || "",
    dob: allLoanData?.dob.split(" ")[0] || "",
    phoneNumber: allLoanData?.phone || "",
    altPhoneNumber: allLoanData?.alternate_phone || "",
    address: allLoanData?.address || "",
    rentstatus: allLoanData?.home_ownership || "",
    landMark: allLoanData?.landmark || "",
    businessStatus: allLoanData?.sub_type || "",
    stateOfResidence: "",
  };
  const today = new Date();
  const minDate = new Date(today);
  minDate.setFullYear(today.getFullYear() - 21);
  const validationSchemaData = {
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .matches(/^[A-Za-z\s\-]+$/, "Only alphabetical characters are allowed")
      .required("Required"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .matches(
        /^[A-Za-z\s\-]+$/,
        "Only alphabetical characters and hyphen are allowed"
      )
      .required("Required"),
    email: Yup.string()
      .required("Email is required")
      .matches(emailRegex, "Invalid email address"),
    bvn: Yup.number()
      .integer("BVN must be a whole number")
      .required("BVN is required")
      .test(
        "len",
        "BVN must be exactly 11 characters",
        (val) => val.toString().length === 11
      ),
    dob: Yup.date()
      .max(
        new Date(today.getFullYear() - 21, today.getMonth(), today.getDate()),
        "You must be at least 21 years old"
      )
      .required("Required"),
    gender: Yup.string().required("Required"),
    maritalStatus: Yup.string().required("Required"),
    altPhoneNumber: Yup.number(),
    phoneNumber: Yup.number()
      .required("Phone number is required")
      .test(
        "len",
        "Phone number should be 11 characters",
        (val) => val.toString().length === 10
      ),
    address: Yup.string().required("Required"),
    rentstatus: Yup.string().required("Required"),
    landMark: Yup.string().required("Required"),
    stateOfResidence: Yup.string().required("State of residence Required"),
    businessStatus:
      allLoanData?.type === "business" ? Yup.string().required("required") : "",
  };

  const submitBtn = (values, { setSubmitting }) => {
    console.log(values, "values");
    let userInfo = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      bvn: values.bvn,
      gender: values.gender,
      marital_status: values.maritalStatus,
      phone: values.phoneNumber.toString(),
      address: values.address,
      home_ownership: values.rentstatus,
      landmark: values.landMark,
      dob: values.dob,
      alternate_phone: values.altPhoneNumber,
      alias: values.maidenName,
      sub_type: values.businessStatus,
      completed: false,
    };

    dispatch(updateField(userInfo));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setCurrentScreen(2);
    localStorage.removeItem("wrongnin");
  };

  useEffect(() => {
    if (!allLoanData.isLoading && loading === "loading") {
      if (allLoanData.isError !== "Rejected" || !allLoanData.isError) {
        toast({
          title: "Loan successfully saved",
          description: "Your application has been saved",
          position: "top-left",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        dispatch(resetState());
        localStorage.removeItem("wrongnin");
        return;
      }
      if (allLoanData.isError === "Rejected" || allLoanData.isError) {
        toast({
          title: "Loan not saved",
          description: "An error occurred. Please try again later",
          position: "top-left",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading("notloading");
        return;
      }
    }

    // eslint-disable-next-line
  }, [allLoanData.isLoading]);

  const saveDetails = async (values) => {
    const { isLoading, isError, allData, isEditing, ...requestData } =
      allLoanData;

    let userInfo = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      bvn: values.bvn,
      gender: values.gender,
      marital_status: values.maritalStatus,
      phone: values.phoneNumber.toString(),
      address: values.address,
      home_ownership: values.rentstatus,
      landmark: values.landMark,
      dob: values.dob,
      alternate_phone: values.altPhoneNumber,
      alias: values.maidenName,
      sub_type: values.businessStatus,
    };

    const updatedData = { ...requestData, ...userInfo };

    if (!(values.firstName && values.lastName && values.email)) {
      return;
    }
    // setLoading("loading")

    dispatch(updateField(updatedData));

    if (request_id) {
      dispatch(editLoan(updatedData)).then((res) => {
        if (res?.payload?.status === true) {
          toast({
            title: "Loan successfully saved",
            description: "Your application has been saved",
            position: "top-left",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          return;
        } else {
          toast({
            title: "Loan not saved",
            description: "An error occurred. Please try again later",
            position: "top-left",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
      return;
    }

    dispatch(requestLoan(updatedData)).then((res) => {
      if (res?.payload?.status === true) {
        toast({
          title: "Loan successfully saved",
          description: "Your application has been saved",
          position: "top-left",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else {
        toast({
          title: "Loan not saved",
          description: "An error occurred. Please try again later",
          position: "top-left",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Flex justify="center">
      <Box
        w={["100%", "80%", "60%"]}
        boxShadow="0px 4px 12px 0px #D7D7D740"
        border="0.5px solid #EFEAED"
        borderRadius="10px"
      >
        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          PERSONAL INFORMATION
        </Text>
        <Divider
          color="#666666"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />
        <Box p={["10px", "20px"]}>
          <Formik
            initialValues={initialvalues}
            validationSchema={Yup.object(validationSchemaData)}
            onSubmit={submitBtn}
          >
            {({ values, errors, touched, handleChange, setValues }) => {
              return (
                <Form>
                  <IneligibleApplicant
                    setIsOpen={() => setIsIneligible(true)}
                    isOpen={isIneligible}
                  />
                  <ScrollToFieldError />
                  <VStack spacing={8}>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="firstName">First Name*</label>
                        </Text>
                        <Field
                          className="form-input"
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                          disabled={allLoanData?.is_bvn_verified ? true : false}
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="firstName" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="lastName">Last Name*</label>
                        </Text>
                        <Field
                          className="form-input"
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                          disabled={allLoanData.is_bvn_verified ? true : false}
                        />
                        <Text id="lastNameError" color="red" fontSize="11px">
                          <ErrorMessage name="lastName" />
                        </Text>
                      </Box>
                    </Stack>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80px"
                        >
                          <label htmlFor="maidenName">
                            Maiden Name (Optional)
                          </label>
                        </Text>
                        <Field
                          className="form-input"
                          name="maidenName"
                          placeholder="Maiden Name"
                          type="text"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="maidenName" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="bvn">BVN*</label>
                        </Text>
                        <Field
                          disabled={allLoanData.is_bvn_verified ? true : false}
                          className="form-input"
                          name="bvn"
                          placeholder="0000000000"
                          type="number"
                          value={values.bvn}
                          onChange={(e) => {
                            if (e.target.value.length <= 11) {
                              handleChange(e);
                            }
                          }}
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="bvn" />
                        </Text>
                      </Box>
                    </Stack>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="gender">Sex*</label>
                        </Text>
                        <Field
                          disabled={allLoanData.is_bvn_verified ? true : false}
                          className="form-input"
                          as="select"
                          name="gender"
                          placeholder="gender"
                        >
                          <option className="optionFields" value="">
                            Select An Option
                          </option>
                          <option className="optionFields" value="male">
                            Male
                          </option>
                          <option className="optionFields" value="female">
                            Female
                          </option>
                        </Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="gender" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="sex">Marital Status*</label>
                        </Text>
                        <Field
                          className="form-input"
                          as="select"
                          name="maritalStatus"
                          placeholder="marital status"
                        >
                          <option className="optionFields">
                            Select An Option
                          </option>
                          <option className="optionFields" value="single">
                            Single
                          </option>
                          <option className="optionFields" value="married">
                            Married
                          </option>
                        </Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="maritalStatus" />
                        </Text>
                      </Box>
                    </Stack>

                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="dob">Date of Birth*</label>
                        </Text>
                        <Field
                          className="form-input"
                          name="dob"
                          placeholder="DD/MM/YYYY"
                          type="date"
                          max="2006-01-01"
                        />
                        <Text color="red" fontSize="11px">
                          {" "}
                          <ErrorMessage name="dob" />{" "}
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="phoneNumber">Phone Number*</label>
                        </Text>
                        <HStack
                          width="100%"
                          p="15px 10px"
                          background="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                          border="0.17px solid rgba(91, 46, 79, 1)"
                          borderRadius="6px"
                        >
                          <HStack spacing={1} pl="5px" mr="10px">
                            <Img
                              src={flag}
                              borderRadius="5px"
                              objectFit="contain"
                            />
                            <Text
                              color="#33343D"
                              fontSize="12px"
                              fontWeight="500"
                              opacity="50%"
                            >
                              +234
                            </Text>
                            <Box color="#848481">
                              <IoIosArrowDown />
                            </Box>
                          </HStack>
                          <Field
                            className="form-input-phonenumber"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            type="number"
                          />
                        </HStack>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="phoneNumber" />
                        </Text>
                      </Box>
                    </Stack>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="altPhoneNumber">
                            Alternate Phone Number
                          </label>
                        </Text>
                        <HStack
                          width="100%"
                          p="15px 10px"
                          background="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                          border="0.17px solid rgba(91, 46, 79, 1)"
                          borderRadius="6px"
                        >
                          <HStack spacing={1} pl="5px" mr="10px">
                            <Img
                              src={flag}
                              borderRadius="5px"
                              objectFit="contain"
                            />
                            <Text
                              color="#33343D"
                              fontSize="12px"
                              fontWeight="500"
                              opacity="50%"
                            >
                              +234
                            </Text>
                            <Box color="#848481">
                              <IoIosArrowDown />
                            </Box>
                          </HStack>
                          <Field
                            className="form-input-phonenumber"
                            name="altPhoneNumber"
                            placeholder="Phone Number"
                            type="number"
                          />
                        </HStack>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="altPhoneNumber" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="email">Email Address*</label>
                        </Text>
                        <Field
                          className="form-input"
                          name="email"
                          placeholder="Enter Mail"
                          type="text"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="email" />
                        </Text>
                      </Box>
                    </Stack>

                    <Box w={["100%"]}>
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="0.8px"
                      >
                        <label htmlFor="stateOfResidence">
                          State Of Residence*
                        </label>
                      </Text>
                      <Field
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value !== "Lagos") setIsIneligible(true);
                          setValues({ ...values, stateOfResidence: value });
                        }}
                        className="form-input"
                        as="select"
                        name="state"
                      >
                        <option className="stateOfResidence" value="">
                          Select An Option
                        </option>
                        {nigeriaStates?.map((item) => {
                          return (
                            <option className="stateOfResidence" value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Field>
                      <Text color="red" fontSize="11px">
                        <ErrorMessage name="stateOfResidence" />
                      </Text>
                    </Box>

                    {allLoanData?.type === "business" && (
                      <Box w="100%">
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="businessStatus">
                            Business Status*
                          </label>
                        </Text>

                        <Field
                          className="form-input"
                          as="select"
                          name="businessStatus"
                        >
                          <option className="optionFields">
                            Select An Option
                          </option>
                          <option className="optionFields" value="individual">
                            Individual{" "}
                          </option>
                          <option className="optionFields" value="corporate">
                            Corporate{" "}
                          </option>
                        </Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="businessStatus" />
                        </Text>
                      </Box>
                    )}
                    <Box w="100%">
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="80%"
                      >
                        <label htmlFor="address">Residential Address*</label>
                      </Text>
                      <Field
                        as="textarea"
                        className="form-textarea"
                        name="address"
                        placeholder="Enter Address"
                        type="text"
                      />
                      <Text color="red" fontSize="11px">
                        <ErrorMessage name="address" />
                      </Text>
                    </Box>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="landMark">
                            Nearest Landmark/Bus Stop*
                          </label>
                        </Text>
                        <Field
                          className="form-input"
                          name="landMark"
                          placeholder="Enter landmark"
                          type="text"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="landMark" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80%"
                        >
                          <label htmlFor="rentstatus">
                            Residential Status*
                          </label>
                        </Text>
                        <Field
                          className="form-input"
                          as="select"
                          name="rentstatus"
                        >
                          <option className="optionFields">
                            Select An Option
                          </option>
                          <option className="optionFields" value="rented">
                            Rented
                          </option>
                          <option className="optionFields" value="owned">
                            Owned
                          </option>
                        </Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="rentstatus" />
                        </Text>
                      </Box>
                    </Stack>
                  </VStack>
                  <Divider
                    mt="50px"
                    color="#5B2E4F"
                    opacity="85%"
                    height="2px"
                    width="100%"
                  />
                  <Flex justify="flex-end" mt="30px">
                    {allLoanData.isLoading ? (
                      <Button
                        color="#5B2E4F"
                        fontSize="12px"
                        mr="5px"
                        isLoading
                        border="1px solid #5B2E4"
                        loadingText="saving"
                      >
                        SAVE
                      </Button>
                    ) : (
                      <Button
                        color="#5B2E4F"
                        fontSize="12px"
                        mr="5px"
                        onClick={() => saveDetails(values)}
                      >
                        SAVE
                      </Button>
                    )}
                    <Button
                      type="submit"
                      bg="#5B2E4F"
                      borderRadius="6px"
                      fontSize="12px"
                      fontWeight="500"
                      color="white"
                      p="13px 52px"
                      _hover={{
                        color: "#5B2E4F",
                        border: "1px solid #5B2E4F",
                        bg: "white",
                      }}
                    >
                      NEXT
                    </Button>
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Flex>
  );
};

export default FirstForm;
